<template>
	<div class="art-detail-box">
		<h3 class="art-detail-title">{{ artCont.title }}</h3>
		<p class="art-detail-time">
			<span class="el-icon-time">{{ timeFormat(artCont.addTime) }}</span>
		</p>
		<div class="art-detail-con" v-html="artCont.content"></div>
	</div>
</template>
<script>
import moment from 'moment'
export default {
	name: 'ArticleDetail',
	props: {
		artCont: {
			type: Object,
			required: true,
		},
	},
	methods: {
		timeFormat(time) {
			return moment(time).format('YY-MM-DD HH:mm:ss')
		},
	},
}
</script>
