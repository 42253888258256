<template>
    <div class="loginWrap">
        <el-breadcrumb separator="|">
            <el-breadcrumb-item to="/login" v-if="!userInfo || userInfo==null">登录</el-breadcrumb-item>
            <el-breadcrumb-item to="/register" v-if="!userInfo || userInfo==null"><span style="cursor: pointer;font-weight: bold;color: #303133;">注册</span></el-breadcrumb-item>
            <el-breadcrumb-item to="/mySpace" v-if="userInfo && userInfo!=null">个人中心：{{userInfo.userName}}</el-breadcrumb-item>
            <el-breadcrumb-item  v-if="userInfo && userInfo!=null"><span @click="logout" style="cursor: pointer;font-weight: bold;color: #303133;">退出</span></el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import instance from '@/services/instance'

export default {
    name: "login-type",
    data(){
        return {
            userInfo:""
        }
    },
    mounted(){
        const that = this
        // 在mouted钩子函数中进行监听localStorage
        this.userInfo=JSON.parse(localStorage.getItem('userInfo'));
        window.addEventListener('setItem', () => {
            that.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        })
        window.removeEventListener('removeItem', () => {
            that.userInfo = localStorage.removeItem('userInfo');
        })
    },
    methods:{
        logout() {
            instance
                .post('/officialWebsiteMgr/resource/loginOut')
                .then((res) => {
                    if (res.code == 0) {
                        this.$removeStorageEvent('userInfo');
                        this.userInfo="";
                        this.$router.push({path: '/'});
                    } else if (res.code == 999) {
                        this.userInfo="";
                        this.$removeStorageEvent('userInfo');
                        this.$message.error(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
        }
    }
}
</script>

<style>
.loginWrap{
    float: right;
    height:100%;
}
</style>