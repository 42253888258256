import axios from "axios";
// import store from "@/store/public/index";
//import store from "@/store/index";
import { Message } from 'element-ui';
import router from "@/router";

let instance = axios.create({
    timeout: 60000,
});

instance.interceptors.request.use(config=>{
    return config
},error=>{
    console.log(error)
});
instance.interceptors.response.use(res=>{
    if(res.status===200){
        if(res.data.code==999){
            Message.error("登陆时间超时，请重新登陆");
            localStorage.removeItem("userInfo");//删除本地缓存信息
            //store.dispatch("user/remove_user_info");
            router.push({
                path: "/home",
            })
            return false;
        }else{
            return Promise.resolve(res.data)
        }
    }else{
        return Promise.reject(res)
    }
})
export default instance;