<template>
	<div>
		<div class="my_artlist_box" v-loading="loading">
			<div class="my_artsearch">
				<div>
					<el-select v-model="type" size="mini" @change="changeType">
						<el-option label="炫物一级" value=""></el-option>
						<el-option
							v-for="item of typeList"
							:key="item.id"
							:label="item.className"
							:value="item.id"
						></el-option>
					</el-select>
					<el-select
						v-model="type1"
						size="mini"
						@change="changeType1"
					>
						<el-option label="炫物二级" value=""></el-option>
						<el-option
							v-for="item of typeList1"
							:key="item.id"
							:label="item.className"
							:value="item.id"
						></el-option>
					</el-select>
					<el-select
						v-model="type2"
						size="mini"
						@change="changeType2"
					>
						<el-option label="炫物三级" value=""></el-option>
						<el-option
							v-for="item of typeList2"
							:key="item.id"
							:label="item.className"
							:value="item.id"
						></el-option>
					</el-select>
					<el-input
						v-model="search.title"
						size="mini"
						@keyup.enter.native="searchList"
					>
						<el-button
							slot="append"
							icon="el-icon-search"
							@click="searchList"
						></el-button>
					</el-input>
					<el-button
						icon="el-icon-refresh-left"
						circle
						size="mini"
						@click="resite"
					></el-button>
				</div>
			</div>
			<div v-if="tableData.length != 0">
				<ul class="my_artlist_ul">
					<li v-for="(item,index) of tableData" :key="item.id">
						<div class="my_artlist_point_img" @click="artDetail(item.id)">
							<!-- @click="artDetail(item.id)" -->
							<img :src="setUrl(item.showImg)" alt="" />
						</div>
						<div class="my_artlist_point" @click="artDetail(item.id)">
							<h2>{{ item.name }}</h2>
							<p>
								<span>阅读{{ item.pageviews }}</span>
								<span>点赞{{ item.likeCount }}</span>
								<span>评论{{ item.commentCount }}</span>
							</p>
							<p>
								<span :class="'st' + item.status">{{
									statusTxtAry[item.status]
								}}</span>
								<i>{{ artType(item.classId) }}</i>
							</p>
							<p>
								<span
									>创建时间：{{
										formatDate(item.addTime)
									}}</span
								>
								<span v-if="item.publishTime"
									>发布时间：{{
										formatDate(item.publishTime)
									}}</span
								>
							</p>
						</div>
						<div class="my_artlist_point_btn">
							<div v-if="['0', '4'].includes(item.status)">
								<span
									class="el-icon-position"
									@click="artPub(item.id,index)"
									>发布</span
								>
							</div>
							<div v-if="['1', '3'].includes(item.status)">
								<span
									class="el-icon-refresh-right"
									@click="artPubDown(item.id,index)"
									>撤回</span
								>
							</div>
							<div v-if="['0', '4'].includes(item.status)">
								<span
									class="el-icon-edit"
									@click="artEdt(item.id,index)"
									>编辑</span
								>
							</div>
							<div v-if="item.status != '1'">
								<span
									class="el-icon-delete"
									@click="artDel(item.id,index)"
									>删除</span
								>
							</div>
							<!-- <span @click="artDetail(item.id)">预览</span> -->
						</div>
					</li>
				</ul>
				<div class="pagestyle">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="currentPage"
						:page-sizes="[10, 20]"
						:page-size="pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total"
					>
					</el-pagination>
				</div>
			</div>
			<div v-else class="artnote">
				<p>还没有炫物，去<a @click="gopub">创作发布</a></p>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisibleDetail" width="920px" :before-close="handleClose">
			<detail-box :coolCont="coolContent"></detail-box>
			<div slot="footer" class="dialog-footer">
				<el-button
					size="mini"
					type="primary"
					@click="handleClose"
					>关 闭</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import instance from '@/services/instance'
import moment from 'moment'
import detailBox from './detail'
export default {
	name: 'ArticleList',
	components: {
		detailBox,
	},
	data() {
		return {
			loading: true,
			tableData: [],
			multipleSelection: [],
			pageSize: 10,
			currentPage: 1,
			total: 0,
			statusTxtAry: [
				'草稿',
				'发布上架',
				'违规下架',
				'审核中',
				'审核失败',
			],
			dialogVisibleDetail: false,
			coolContent: {},
			search: {
				classId: '00001',
				title: '',
			},
			type: '',
			type1: '',
			type2: '',
			typeList: [],
			typeList1: [],
			typeList2: [],
		}
	},
	computed: {
		artType() {
			return (key) => {
				let tar = this.typeList.filter((item) => {
					return item.id == key
				})
				if (tar.length != 0) {
					return '类别：' + tar[0].className
				} else {
					return ''
				}
			}
		},
	},
	created() {
		this.initList()
		this.getArtClassify(this.type).then(res=>{
      this.typeList = res;
    })
	},
	methods: {
		initList() {
			this.loading = true
			let sdata = {
				startPage: this.currentPage,
				pageSize: this.pageSize,
				classId: this.search.classId,
				name: this.search.title,
			}
			console.log(sdata)
			instance
				.post('/officialWebsiteMgr/resource/show_favor/userList', sdata)
				.then((res) => {
					this.loading = false
					if (res.code == 0) {
						this.tableData = res.data.list;
						this.total = res.data.totalCount;
					}
				})
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.currentPage = 1
			this.initList()
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.initList()
		},
		artEdt(id) {
			this.$router.push({
				name: 'MyPetCreate',
				params: {
					type: 'edit',
					id: id,
				},
			})
		},
		artPub(id,index) {
			this.$confirm('发布炫物，是否发布？', '提示', {
				confirmButtonText: '发布',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					instance
						.post(
							'/officialWebsiteMgr/resource/show_favor/upStatus',
							{
								id: id,
								status: 3,
							}
						)
						.then((res) => {
							if (res.code == 0) {
								this.tableData[index].status="3";
								this.$message({
									type: 'success',
									message: '操作成功等待审核！',
								})
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发布',
					})
				})
		},
		artPubDown(id,index) {
			this.$confirm('炫物撤回操作，是否继续？', '提示', {
				confirmButtonText: '继续',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					instance
						.post(
							'/officialWebsiteMgr/resource/show_favor/upStatus',
							{
								id: id,
								status: 0,
							}
						)
						.then((res) => {
							if (res.code == 0) {
                                this.tableData[index].status="0";
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '下架操作取消',
					})
				})
		},
		artDel(id,index) {
			this.$confirm('炫物删除，是否继续？', '提示', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'error',
			})
				.then(() => {
					instance
						.post('/officialWebsiteMgr/resource/show_favor/del', {
							id: id,
						})
						.then((res) => {
							if (res.code == 0) {
                                this.tableData.splice(index,1);
                                this.total-=1;
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '删除操作取消',
					})
				})
		},
		artDetail(key) {
		    window.open("/#/coolDetail/"+key, '_blank');
		  /*instance.post("/officialWebsiteMgr/resource/show_favor/desc",{id:key}).then(res=>{
			if (res.code == 0) {
			  this.dialogVisibleDetail = true
			  this.coolContent = res.data
			}
		  })*/
		},
		formatDate(time) {
			return moment(time).format('YY-MM-DD HH:mm:ss')
		},
		//图片展示
		setUrl(url) {
			// return url;
			if (url.indexOf('http') == 0) {
				return url
			} else {
				return (
					'/officialWebsiteMgr/resource/file/getImage?fileName=' + url
				)
			}
		},
		getArtClassify(id) {
			return new Promise((reslove, reject) => {
				instance
					.post('/officialWebsiteMgr/resource/show_favor/classList', {
						pid: id,
					})
					.then((res) => {
						if (res.code == 0) {
              reslove(res.data)
						}
					})
			})
		},

		changeType() {//下拉一级
            this.type1="";
            this.type2="";
			this.getArtClassify(this.type).then(res=>{
				this.typeList1 = res;
        	})
			if(this.type==""){
                this.search.classId = _.cloneDeep("00001");
			}else{
                this.search.classId = _.cloneDeep(this.type);
			}
      		this.searchList();
    	},
		changeType1() {//下拉二级
			this.type="";
            this.type2="";
			this.getArtClassify(this.type1).then(res=>{
				this.typeList2 = res;
			})
            if(this.type1==""){
                this.search.classId = _.cloneDeep("00001");
            }else{
                this.search.classId = _.cloneDeep(this.type1);
            }
		  this.searchList();
		},
    	changeType2() {//下拉三级
            this.type="";
            this.type1="";
            if(this.type2==""){
                this.search.classId = _.cloneDeep("00001");
            }else{
                this.search.classId = _.cloneDeep(this.type2);
            }
      		this.searchList();
		},
		searchList() {
		    this.currentPage=1;
			this.initList();
		},
		resite() {
            this.type="";
            this.type1="";
            this.type2= "";
			this.search.title = '';
			this.search.classId = '00001';
            this.currentPage=1;
			this.initList();
		},
		gopub() {
			this.$router.push({
				name: 'MyPetCreate',
				params: {
					type: 'create',
				},
			})
    	},
		handleClose(){
		  console.log(111)
		  this.coolContent = {};
		  this.dialogVisibleDetail = false;
		}
	},
}
</script>
