<template>
    <div>
        <div class="header" style="box-shadow: 0 2px 5px #eeeeee;">
            <div class="searchHead">
                <img class="logo" src="~@/assets/logo.png" alt="" />
                <img src="~@/assets/logoTxt.png" />
                <router-link to="/home">首页</router-link>
                <div class="searchWrap">
                    <div class="searchSelect">
                        <el-select v-model="value" placeholder="文章">
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="searchIps">
                        <el-input placeholder="请输入内容" v-model="txt">
                            <el-button style="padding-right:10px" slot="suffix" type="text"  @click="search">搜索</el-button>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="searchCont">
            <el-container class="content_main">
                <el-main class="content_main_context" v-if="list.length!=0">
                    <div class="searchlist">
                        <ul>
                            <li v-for="item in list" :key="item.id" @click="goDetail(item.id)">
                                <div class="searchList_point">
                                    <p class="searchList_title">
                                        {{ item.title || item.name }}
                                    </p>
                                    <p class="searchList_content">
                                        {{ item.contentTxt }}
                                    </p>
                                    <p class="searchList_looked">
                                        {{ setMoment(item.addTime) }}
                                        <span class="look">{{ item.pageviews }}</span>
                                        <span class="like">{{ item.likeCount }}</span>
                                        <span class="discount">{{ item.commentCount }}</span>
                                    </p>
                                </div>
                                <div class="searchList_img">
                                    <img :src="setUrl(item.showImg)" />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-size="10"
                            layout="total,  prev, pager, next, jumper"
                            :total="totalArt"
                    >
                    </el-pagination>
                </el-main>
                <el-main v-else>
                    数据空
                </el-main>
            </el-container>
        </div>
        <div class="footer">
            <p>地址：北京市房山区城关街道顾八路1区1号-P188</p>
            <p>版权所有 北京鱼海科技有限公司 <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">京ICP备18052954号-2</a></p>
        </div>
    </div>
</template>

<script>
import fetch from '@/services/service'
import moment from 'moment'

    export default {
        name: "Search",
        data() {
            return {
                txt:"",//搜索内容
                options: [],//下拉选项
                value: '',//下拉选中项
                artlist:[],//文章
                list:[],
                currentPage: 1,
                totalArt: 0,
            }
        },
        created() {
            this.options=[{
                value: '文章',
                label: '',
            }, {
                value: '00001',
                label: '炫物',
            }, {
                value: '00033',
                label: '足迹',
            }]
            this.txt = this.$route.params.txt;
        },
        methods: {
            setUrl(url) {
                if(url.indexOf("http")==0){
                    return url;
                }else{
                    return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
                }
            },
            setMoment(time) {
                return moment(time).format('YY-MM-DD HH:mm:ss')
            },
            search(){
                if(this.value==""){
                    this.getArtList();
                }else{
                    this.getList(this.value,this.txt);
                }
            },
            getArtList() {
                //文章
                fetch
                    .post('/officialWebsiteMgr/resource/article/indexArticle',{
                        title:this.txt
                    })
                    .then((res) => {
                        if (res.code == 0) {
                            this.list = res.data.list;
                            this.totalArt = res.data.totalCount;
                        }
                    })
            },
            getList(id,name) {
                //炫物和足迹
                let sdata = {
                    classId: id,
                    name:name,
                    startPage: 1,
                    pageSize: 10,
                };
                fetch
                    .post(
                        '/officialWebsiteMgr/resource/show_favor/indexList',
                        sdata
                    )
                    .then((res) => {
                        if (res.code == 0) {
                            this.list = res.data.list;
                            this.totalArt = res.data.totalCount;
                        }
                    })
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getList(this.classId,this.trackActive);
            },
            goDetail(id){
                if(this.value==""){
                    this.$router.push({
                        name: 'ArticleDetail',
                        params: { id: id },
                    })
                }else if(this.value=="00001"){
                    this.$router.push({
                        name: 'CoolDetail',
                        params: { id: id },
                    })
                }else if(this.value=="00033"){
                    this.$router.push({
                        name: 'footDetail',
                        params: { id: id },
                    })
                }
            }
        }
    }
</script>

<style scoped>
.searchHead{
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.searchHead>img:nth-child(2){
    margin-left:25px;
}
.searchHead>a{
    margin:0 30px;
    text-decoration: none;
    color:#333;
    padding:0 10px;
    font-size:14px;
    font-weight: 900;
}
.searchWrap{
    display: flex;
    width:400px;
}
.searchSelect{
    width:150px;
}
.searchIps{
    width:300px;
}
.searchCont{
    padding-bottom: 100px;
}
.searchlist{
    padding-top:20px;
}
.searchlist ul li {
    padding: 20px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background:#fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}
.searchList_point {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 20px;
}
.searchList_looked{
    height: 20px;
    line-height: 20px;
    margin-top: 5px;
    font-size: 12px;
    color: #999999;
}
.searchList_looked span{
    margin-left: 40px;
    padding-left: 20px;
    display: inline-block;
}
.searchList_looked .look {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAKCAMAAABhV5WtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3NpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiOTFlMzM1ZS1mZTIzLTczNDktODYzNy1lOWFkZjExNDQxMGIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDI3MDBCRjE5REJGMTFFQTkwMzU5NjdBQzM5QjBDMzQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDI3MDBCRjA5REJGMTFFQTkwMzU5NjdBQzM5QjBDMzQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZDE1YjJiZWUtNWUzNi05ZjQxLTgyNzUtMzkyYjE5MDU2ZWFjIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOmNlNzkxZDcwLTFiOWItYmY0My05ZmM4LTQxNDU4Njc0YTA1MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp3KtZYAAADMUExURfv7+/Hx8eHh4aGhoZGRkY2NjfX19ZaWlufn55WVlbKysq6urtXV1aCgoNTU1MzMzOPj452dndnZ2ezs7I+Pj4WFhYeHh/r6+s/Pz5eXl9vb24iIiJOTk+Li4vn5+d/f38DAwOnp6ZiYmIaGhouLi+rq6qWlpaenp93d3cbGxu3t7evr683NzcrKyoSEhJubm7GxsZqamoyMjMTExMvLy+/v75CQkImJieXl5bm5ubCwsIGBgba2tqmpqa+vr46Ojq2trf39/ZmZmf///+3j9LwAAABEdFJOU/////////////////////////////////////////////////////////////////////////////////////////8AcdKPEgAAAJ5JREFUeNokTgUOw0AMS5nWlWHMzMxr7/L/Py3XRYplW3JiQBp2ClRNOxaGYwoJtOvU9SS4X1jzfZvqlfcZy4jLQhv1Se4THyGbKYzSuw3q1zmF6skKSkWciIYE56egoQplKciEE+QVNQvoclu8MkLERo2Yn3YAM36QEHstJXbIWrzqVZfH1paZnssYeao1+PdD6Vu0uRtYMRdp/AkwADMJIZVCqrPeAAAAAElFTkSuQmCC) no-repeat left center;
}
.searchList_looked .like {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAMAAAC38k/IAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3NpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiOTFlMzM1ZS1mZTIzLTczNDktODYzNy1lOWFkZjExNDQxMGIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDI3MDBCRjk5REJGMTFFQTkwMzU5NjdBQzM5QjBDMzQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDI3MDBCRjg5REJGMTFFQTkwMzU5NjdBQzM5QjBDMzQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZDE1YjJiZWUtNWUzNi05ZjQxLTgyNzUtMzkyYjE5MDU2ZWFjIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOmNlNzkxZDcwLTFiOWItYmY0My05ZmM4LTQxNDU4Njc0YTA1MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pt3wV5IAAACfUExURWFkdXl8imRoePr6+32AjnZ5h3d6iPf3+NPU2WNmduPk59fY3FhcbZeapOTl6Pr6+qyutsnK0JGUn3x/jbu8w8HCya6wuPn5+pWYo9XW22FldcbIzuvr7XV4h4aIldHS1+jo62Jmdvf4+PX19mxwf72/xtHS1ouOmlZaa9LT2L/Ax4WIlPv7+5udp+3t79na3sTGzNTV2cvM0mVoeP///++edB0AAAA1dFJOU/////////////////////////////////////////////////////////////////////8AfLXQCgAAAGtJREFUeNosjEcWglAAAwMfkGLFgtg7dlHn/mfjwyOLJLMZQZb3ofPdgfhEvpve5KiHzH/EYHy9cPAnSgJgNbRVPpSsabPc6BmFLXh38XPj5r88a2O62Fp14RxrINzP4tPc0ADvoHuutxJgABNAENFWxMG2AAAAAElFTkSuQmCC) no-repeat left center;
}
.searchList_looked .discount {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAMAAABYMCT2AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3NpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiOTFlMzM1ZS1mZTIzLTczNDktODYzNy1lOWFkZjExNDQxMGIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDI3NDBENUU5REJGMTFFQTkwMzU5NjdBQzM5QjBDMzQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDI3NDBENUQ5REJGMTFFQTkwMzU5NjdBQzM5QjBDMzQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZDE1YjJiZWUtNWUzNi05ZjQxLTgyNzUtMzkyYjE5MDU2ZWFjIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOmNlNzkxZDcwLTFiOWItYmY0My05ZmM4LTQxNDU4Njc0YTA1MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pr8pAdIAAACiUExURW5ubvv7+/39/fHx8Xp6eqysrP7+/rW1tfX19dHR0evr62tra+7u7vDw8NnZ2fT09OHh4dDQ0PLy8re3t9LS0ujo6J6enru7u7KysoqKio+Pj4iIiK2trY2NjbS0tHR0dKSkpHd3d9vb23l5edPT0+Li4m1tbeXl5fz8/IWFhePj43BwcNbW1q6uru/v76+vr7a2tvj4+HFxcff394yMjP////iU4wYAAAA2dFJOU///////////////////////////////////////////////////////////////////////AKGPTjEAAABzSURBVHjaPIvXDoJAAASX3sQGAiqKDUTxOvf/vyYCcbIvk8zicDz1M/sXoqcQSo2zIsiFjg0dmy51WzkYvQc7e1lf1+ZgoZFS9dj6gS8kYOk/HaoLI+xclIQwp8HH4zy34SScexv8ilV2m9LR3uF8/AowANX1EmCv7zuRAAAAAElFTkSuQmCC) no-repeat left center;
}
.searchList_content{
    color: #666666;
    display: block;
    height: 40px;
    line-height: 20px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.searchList_img {
    width: 150px;
    overflow: hidden;
    position: relative;
}
.searchList_img img{
    width: 150px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
</style>