<template>
    <div>
        <div class="jumpCont">
            <p>{{msg}}</p>
            <p><el-button type="primary"><router-link to="/mySpace/personMessage">去个人信息</router-link></el-button></p>
        </div>
    </div>
</template>

<script>
    import instance from '@/services/instance'

    export default {
        name: "jumpWx",
        data() {
            return {
                msg:"",
            }
        },
        created(){
            let code = this.$route.params.code;
            this.msg = this.$route.params.msg;
            this.jump(code)
        },
        methods:{
            jump(code){
                let that = this;
                setTimeout(function(){
                    if(code==0){//  0 微信绑定成功 1微信绑定失败
                        that.getUserMessage();
                    }else if(code==1){
                        that.$message.error(that.msg);
                    }
                },2000 )
            },
            getUserMessage() {
                instance
                    .post('/officialWebsiteMgr/resource/user/userDesc')
                    .then(res => {
                        if (res.code == 0) {
                            this.$addStorageEvent('userInfo', JSON.stringify(res.data));//存储数据,addStorageEvent自定义方法
                            this.$router.push({path: '/mySpace'});
                        }
                    })
            },
        }
    }
</script>

<style scoped>
.jumpCont{
    width:1120px;
    margin:10px auto;
    border:solid #d7d7d7 1px;
    background:#fff;
    padding:100px 0;
    text-align: center;
}
.jumpCont p{
    margin-bottom: 10px;
}
.jumpCont a{
     color:#fff;
    text-decoration: none;
 }
</style>