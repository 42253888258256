<template>
	<div>
		<el-container>
			<div class="shop-box">
				<div class="shop-title">
					<img src="@/assets/img/shop.png" alt="" />
					<span>{{ shopName }}</span>
				</div>
				<div class="shop-txt">
					<h3>店铺简介</h3>
					<p>{{ shopCon }}</p>
					<h3>
						店铺地址：<span>{{ shopAdr }}</span>
					</h3>
				</div>
				<div class="shop-pet-box">
					<div v-for="item in shopPetList" :key="item.id">
						<img :src="item.img" alt="" />
						<p>{{ item.name }}</p>
					</div>
				</div>
			</div>
			<el-aside width="220px">
				<div class="shop-oth">
					<h3>其他商铺</h3>
					<ul class="shop-oth-box">
						<li v-for="item in shopOthList" :key="item.id">
							<p>{{ item.name }}</p>
						</li>
					</ul>
				</div>
			</el-aside>
		</el-container>
	</div>
</template>
<script>
export default {
	name: 'shop',
	data() {
		return {
			shopName: '欢迎来到人人小店人人小店人人小店人人小店',
			shopCon:
				'我们主营狗各种狗，我们主营狗各种狗我们主营狗各种狗我们主营狗各种狗我们主营狗各种狗我们主营狗各种狗我们主营狗各种狗我们主营狗各种狗我们主营狗各种狗',
			shopAdr: '大王街小王路110号3层301',
			shopPetList: [
				{ id: 1, name: '阿拉斯加', img: require('@/assets/img/3.jpg') },
				{ id: 2, name: '阿拉斯加', img: require('@/assets/img/3.jpg') },
				{ id: 3, name: '阿拉斯加', img: require('@/assets/img/3.jpg') },
				{ id: 4, name: '阿拉斯加', img: require('@/assets/img/3.jpg') },
				{ id: 5, name: '阿拉斯加', img: require('@/assets/img/3.jpg') },
			],
			shopOthList: [
				{ id: 1, name: '小蜜的店renren小店名字惆怅不小蜜的店renren' },
				{ id: 2, name: '小蜜的店renren小店名字惆怅不' },
				{ id: 3, name: '小蜜的店renren小店名字惆怅不' },
				{ id: 4, name: '小蜜的店renren小店名字惆怅不' },
				{ id: 5, name: '小蜜的店renren小店名字惆怅不' },
			],
		}
	},
}
</script>
<style scoped>
.shop-box {
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	padding: 20px;
	margin-right: 30px;
}
.shop-title {
	height: 50px;
	line-height: 50px;
	font-size: 16px;
}
.shop-title img {
	height: 50px;
	width: 50px;
	border-radius: 3px;
	float: left;
	margin-right: 20px;
}
.shop-txt {
	margin-top: 10px;
	padding: 0 20px;
}
.shop-txt h3 {
	font-size: 12px;
	border-bottom: 1px dotted #e5e5e5;
	font-weight: normal;
	color: #888;
}
.shop-txt p {
	text-indent: 24px;
	font-size: 14px;
	line-height: 25px;
	color: #888;
}
.shop-txt h3 span {
	color: #1296db;
}
.shop-pet-box {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
}
.shop-pet-box div {
	border: 1px solid #1296db;
	border-radius: 3px;
	width: 240px;
	height: 210px;
	overflow: hidden;
	flex: 1;
	max-width: 240px;
	min-width: 240px;
	margin: 10px;
	padding: 10px;
}
.shop-pet-box div img {
	width: 240px;
	height: 180px;
	border-radius: 3px;
	display: inline-block;
	cursor: pointer;
}
.shop-pet-box div p {
	padding: 0;
	margin: 0;
	height: 30px;
	line-height: 30px;
	font-size: 14px;
	cursor: pointer;
}
.shop-oth {
	padding: 0;
}
.shop-oth h3 {
	padding: 0;
	margin: 0;
	height: 20px;
	line-height: 20px;
	font-size: 14px;
	color: #888;
	font-weight: normal;
	border-bottom: 1px dotted #1296db;
	margin-bottom: 10px;
}
.shop-oth-box {
	margin: 0;
	padding: 0;
}
.shop-oth-box li {
	margin-bottom: 10px;
}
.shop-oth-box li p {
	margin: 0;
	cursor: pointer;
}
</style>
