<template>
    <div style="background:#fff;padding-bottom:20px;">
        <el-carousel :interval="5000" arrow="always" height="300">
            <el-carousel-item>
                <div class="banner">
                    <img src="~@/assets/img/banner1.jpg" alt="">
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="banner">
                    <img src="~@/assets/img/banner1.png" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="cont">
            <h2>炫物专区<p></p></h2>
            <div class="cool">
                <div class="index_menu">
                    <span v-for="(item,index) in coolNav" @click="getCoolList(item.id,index,'cool')" :class="coolActive==index?'homeActive':''" :key="index">{{item.className}}</span>
                </div>
                <div class="index_List">
                    <dl v-for="(items,index) in coolList" :key="index" @click="coolDetail(items.id)">
                        <dt><img :src="setUrl(items.showImg)" /></dt>
                        <dd>
                            <h5>{{items.name}}</h5>
                            <p>{{ setMoment(items.addTime) }}</p>
                            <p>
                                <span>赞 {{items.pageviews}} </span>
                                <span>阅 {{items.likeCount}} </span>
                                <span>评 {{items.commentCount}} </span>
                            </p>
                        </dd>
                    </dl>
                </div>
            </div>
            <h2>文章专区<p></p></h2>
            <div class="article">
                <div class="articleList">
                    <div v-for="item in artlist" :key="item.id" @click="artDetail(item.id)" class="articleMin">
                        <div class="articleInfo">
                            <h4>{{item.title}}</h4>
                            <p>{{item.contentTxt}}</p>
                        </div>
                        <div class="articleImg" @click="toDetail(item.id)">
                            <img :src="setUrl(item.showImg)" />
                        </div>
                    </div>
                </div>
                <div class="homeAdvertising"></div>
            </div>
            <h2>足迹专区<p></p></h2>
            <div class="track">
                <div class="index_menu">
                    <span v-for="(item,index) in trackNav" @click="getCoolList(item.id,index,'track')" :class="trackActive==index?'homeActive':''" :key="index">{{item.className}}</span>
                </div>
                <div class="index_List">
                    <dl v-for="(items,index) in trackList" :key="index" @click="footDetail(items.id)">
                        <dt><img :src="setUrl(items.showImg)" /></dt>
                        <dd>
                            <h5>{{items.name}}</h5>
                            <p>{{ setMoment(items.addTime) }}</p>
                            <p>
                                <span>赞 {{items.pageviews}} </span>
                                <span>阅 {{items.likeCount}} </span>
                                <span>评 {{items.commentCount}} </span>
                            </p>
                        </dd>
                    </dl>
                </div>
            </div>
            <h2>活动专区<p></p></h2>
            <div class="activity">
                <div class="activityMin" v-for="(item,index) in activityList" :key="index" @click="activityDetail(item.activityId)">
                    <img :src="setUrl(item.showImg)" alt="">
                    <p>{{item.activityName}}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import fetch from '@/services/service'
import moment from 'moment'

    export default {
        name: "index",
        data() {
            return {
                coolActive: 0,
                coolNav:[],
                coolList:[],
                trackActive:0,
                trackNav:[],
                trackList:[],
                activityList:[],
                artlist:[]
            };
        },
        created() {
            this.coolTags("00001","cool");//炫物
            this.getArtList();//文章
            this.coolTags("00033","track");//足迹
            this.getActivity();//活动
        },
        methods: {
            setMoment(time) {
                return moment(time).format('YY-MM-DD HH:mm:ss')
            },
            setUrl(url) {
                if(url.indexOf("http")==0){
                    return url;
                }else{
                    return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
                }
            },
            coolTags(id,type) {
                //导航
                fetch
                    .post("/officialWebsiteMgr/resource/show_favor/classList", { pId: id })
                    .then((res) => {
                        if (res.code == 0) {
                            if(type=="cool"){
                                this.coolNav = res.data;
                            }else if(type=="track"){
                                this.trackNav = res.data;
                            }
                            this.getCoolList(res.data[0].id,0,type);
                        }
                    });
            },
            getCoolList(id,index,type) {
                //列表
                let sdata = {
                    classId: id,
                    name:"",
                    startPage: 1,
                    pageSize: 4,
                };
                fetch
                    .post(
                        '/officialWebsiteMgr/resource/show_favor/indexList',
                        sdata
                    )
                    .then((res) => {
                        if (res.code == 0) {
                            if(type=="cool"){
                                this.coolList = res.data.list;
                                this.coolActive = index;
                            }else if(type=="track"){
                                this.trackList = res.data.list;
                                this.trackActive = index;
                            }
                        }
                    })
            },
            getArtList() {
                //文章
                fetch
                    .post('/officialWebsiteMgr/resource/article/hotArticle',{})
                    .then((res) => {
                        if (res.code == 0) {
                            this.artlist = res.data;
                        }
                    })
            },
            artDetail(id) {
                this.$router.push({
                    name: 'ArticleDetail',
                    params: { id: id },
                })
            },
            coolDetail(id) {
                console.log(id)
                this.$router.push({
                    name: 'CoolDetail',
                    params: { id: id },
                })
            },
            footDetail(id) {
                this.$router.push({
                    name: 'footDetail',
                    params: { id: id },
                })
            },
            activityDetail(id) {
                this.$router.push({
                    name: 'activityDetail',
                    params: { id: id },
                })
            },
            getActivity(){
                //活动
                var sdata = {
                    actName: "",
                    startPage: 1,
                    pageSize:3,
                }
                fetch
                    .post('/officialWebsiteMgr/resource/activity/indexList',sdata)
                    .then((res) => {
                        if (res.code == 0) {
                            this.activityList=res.data.list;
                        }
                    })
            }
        }
    }
</script>

<style scoped>
.cont{
    width:1200px;
    margin:0 auto;
}
.cont>h2{
    font-size: 30px;
    color:#333;
    text-align: center;
    margin:40px 0 20px;
    font-weight: normal;
    /* background:url("../assets/img/titleBg.png")no-repeat center center; */
}
.cont>h2 p{
    width:75px;
    height:3px;
    background:#4cb8df;
    margin:10px auto 0;
}
.index_menu{
    height:40px;
    margin:10px 0;
}
.index_menu span{
    font-size: 16px;
    color: #333;
    cursor: pointer;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right:10px;
}
.index_menu span:hover{
    background:#ccc;
    color:#fff;
}
.index_menu .homeActive{
    color: #fff;
    background-color: #02aae8;
}
.index_List{
    overflow: hidden;
}
.index_List dl{
    float:left;
    border:solid 1px #e1e1e1;
    color:#313131;
    background:#fff;
    margin-right: 30px;
    width: 275px;
    cursor: pointer;
}
.index_List dl:last-child{
    margin-right:0;
}
.index_List img{
    width:100%;
    height:275px;
}
.index_List dd{
    padding:15px 20px;
}
.index_List h5{
    font-weight: normal;
    font-size:18px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.index_List dd p{
    font-size:14px;
}
.index_List dd p:nth-child(2){
    margin:10px 0;
}
/*文章*/
.article{
    overflow: hidden;
}
.articleList{
    padding:0 20px 0 60px;
    width:68%;
    float: left;
}
.articleMin{
    border-bottom: dashed 1px #dadada;
    padding:20px 0;
    cursor: pointer;
    overflow: hidden;
}
.articleInfo{
    float:left;
}
.articleImg{
    float:right;
    width: 150px;
    height: 100px;
    overflow: hidden;
    background-color: #d8d8d8;
    position: relative;
    cursor: pointer;
}
.articleImg img{
    width: 150px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.articleList h4{
    font-size:18px;
    font-weight: normal;
    color:#323232;
}
.articleList p{
    font-size:14px;
    color:#818181;
    padding:25px 0;
}
.homeAdvertising{
    background:#ccc;
    width:300px;
    height:300px;
    border-radius:8px;
    float: right;
}
    /*活动*/
.activity{
   overflow: hidden;
}
.activityMin{
    position: relative;
    float:left;
    margin:0 24px 24px 0;
    width:32%;
    cursor: pointer;
}
.activityMin:nth-child(3n){
    margin-right:0;
}
.activityMin img{
    width:100%;
    height:290px;
    border-radius: 10px;
}
.activityMin p{
    position: absolute;
    bottom:20px;
    left:0;
    right:0;
    margin:auto;
    background:rgba(255,255,255,0.5);
    height:40px;
    text-align: center;
    color:#2e3235;
    font-size: 18px;
    line-height:40px;
    width:80%;
}
</style>