<template>
    <div class="agreement">
        <div class="agreementTit">
            <h1>用户协议</h1>
        </div>
        <div class="agreementCont">
            <p>为了更好地为您提供服务，请您仔细阅读《用户协议》（以下简称 “本协议”）。在您开始使用宠萌星球号平台服务（以下简称 “本服务”）之前，请您务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。</p>
            <p>除非您完全接受本协议的全部内容，否则您无权注册、登录、使用本服务，或者获得娱有趣平台提供的任何服务 （本协议中统称 “使用”）。若您使用本服务，则视为您已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。</p>
            <p>用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与宠萌星球之间自动形成协议关系，用户应当受本协议的约束。用户在使用特殊的服务或产品时，应当同意接受相关协议后方能使用。</p>
            <p>本协议则可由娱有趣随时更新，用户应当及时关注并同意本站不承担通知义务。本站的通知、公告、声明或其它类似内容是本协议的一部分</p>
            <p>一、服务内容</p>
            <p>1.1 娱有趣内容由本站根据实际情况提供。</p>
            <p>1.2 本站仅提供相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行负担。</p>
            <p>二、关于“账号”</p>
            <p>2.1 经本站注册系统，完成注册或授权注册成为正式用户，可以获得本站规定用户所应享有的一切权限；违规操作或扰乱社区秩序帐号本站可直接封号或享有规定的部分权限。宠萌星球有权对用户的权限设计进行变更。</p>
            <p>2.2 用户只能按照注册要求进行注册。用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本站不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请通知本站。因不可抗因素行为或用户的保管疏忽导致帐号非法使用，本站不承担任何责任。</p>
            <p>三、使用规则</p>
            <p>3.1 遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号)》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。</p>
            <p>3.2您按规定完成注册后，可以通过登录注册账号使用本服务。请您保证对在通过娱有趣平台制作、复制、上传、发布、传播、评论的相关内容（包括但不限于头像、名称、账号介绍，或文字、图片、音频、视频、链接等，以及其他使用娱有趣平台服务所产生的其他内容）享有合法权益，且您的行为（包括但不限于制作、复制、上传、发布、传播、评论相关内容的行为）不侵犯任何第三方合法权益。否则，因此产生的所有纠纷及责任，，均应由您独立承担，同时公司因此遭受的损失，您应负责赔偿。</p>
            <p>3.3 用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本站任何页面发布、转载、传送含有下列内容之一的信息，否则本站有权自行处理并不通知用户：</p>
            <p>（1）反对宪法确定的基本原则的；</p>
            <p>（2）危害国家安全，泄露国家秘密的；</p>
            <p>（3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；</p>
            <p>（4）损害国家荣誉和利益的；</p>
            <p>（5）宣扬恐怖主义、极端主义的；</p>
            <p>（6）宣扬民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>（7）煽动地域歧视、地域仇恨的；</p>
            <p>（8）破坏国家宗教政策，宣扬邪教和迷信的；</p>
            <p>（9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；</p>
            <p>（10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；</p>
            <p>（11）侵害未成年人合法权益或者损害未成年人身心健康的；</p>
            <p>（12）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；</p>
            <p>（13）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；</p>
            <p>（14）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</p>
            <p>（15）侮辱或者诽谤他人，侵害他人合法权益的；</p>
            <p>（16）对他人进行暴力恐吓、威胁，实施人肉搜索的；</p>
            <p>（17）涉及他人隐私、个人信息或资料的；</p>
            <p>（18）散布污言秽语，损害社会公序良俗的；</p>
            <p>（19）侵犯他人隐私权、名誉权、肖像权、知识产权、商业秘密等合法权益内容的；</p>
            <p>（20）未经公司许可，利用本服务为自己或第三方进行推广、发布广告的（包括但不限于加入第三方链接、广告等行为）；</p>
            <p>（21）过度营销信息，骚扰信息和 / 或垃圾信息、低俗类信息、垃圾广告的；</p>
            <p>（22）使用本网站常用语言文字以外的其他语言文字制作、复制、上传、发布、传播的内容、留言、评论的；</p>
            <p>（23）与所制作、复制、上传、发布、传播的内容、留言、评论的信息毫无关系的；</p>
            <p>（24）所发布、传播的内容毫无意义的，或刻意使用字符组合以逃避技术审核的；</p>
            <p>（25）其他违反法律法规、政策及公序良俗、干扰搜狗号平台正常运营或侵犯其他用户或第三方合法权益内容的其他信息。</p>
            <p>3.4用户承诺对其发表或者上传于本站的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、视频、电影、表演和录音录像制品和电脑程序等)均为原创或已受合法授权，一经上传、发布的任何内容的知识产权归宠萌星球所有。</p>
            <p>3.5如用户在使用网络服务时违反上述任何规定，本站有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</p>
            <p>四、隐私保护</p>
            <p>4.1 本站不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本站的非公开内容，但下列情况除外：</p>
            <p>(1)事先获得用户的明确授权；</p>
            <p>(2)根据有关的法律法规要求；</p>
            <p>(3)按照相关政府主管部门的要求；</p>
            <p>(4)为维护社会公众的利益。</p>
            <p>4.2 本站可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本站同等的保护用户隐私的责任，则本站有权将用户的注册资料等提供给该第三方。</p>
            <p>4.3 在不透露单个用户隐私资料的前提下，本站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
            <p>五、版权声明</p>
            <p>5.1 本站的文字、图片、音频、视频等版权均归北京鱼海科技有限公司享有。</p>
            <p>5.2 本站特有的标识、版面设计、编排方式等版权均属北京鱼海科技有限公司享有，未经本站许可，不得任意复制或转载。</p>
            <p>5.3 使用本站的任何内容均应事先经平台书面许可并注明“来源于娱有趣”，未按规定擅自使用的，自行承担一切法律责任。</p>
            <p>5.4 本站享有所有作品其它用途的使用权，包括但不限于应用程序、网站、电子杂志、平面出版等，并有权授权其他公司主体使用。</p>
            <p>5.5 本站所有内容仅代表作者自己的立场和观点，与本站无关，由作者本人承担一切法律责任。</p>
            <p>5.6 恶意转载本站内容的，本站保留将其诉诸法律的权利。</p>
            <p>六、责任声明</p>
            <p>6.1 用户明确同意其使用本站网络服务所存在的风险及一切后果将完全由用户本人承担，娱有趣对此不承担任何责任。</p>
            <p>6.2 本站无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。</p>
            <p>6.3 本站不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本站实际控制的任何网页上的内容，本站不承担任何责任。</p>
            <p>6.4 对于因不可抗力或本站不能控制的原因造成的网络服务中断或其它缺陷，本站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
            <p>6.5 对于本站向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，本站无需承担任何责任：</p>
            <p>(1)本站向用户免费提供的各项网络服务；</p>
            <p>(2)本站向用户赠送的任何产品或者服务；</p>
            <p>(3)未经过本站监管进行交易等买卖的服务；</p>
            <p>6.6 本站有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，本站对用户和任何第三人均无需承担任何责任。</p>
            <p>七、附则</p>
            <p>7.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
            <p>7.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
            <p>7.3 在法律许可范围内本活动最终解释权归娱有趣所有。</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "agreement"
    }
</script>

<style scoped>
.agreement{
    width:1000px;
    margin:10px auto;
    background:#fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding:20px;
    font-size:14px;
}
    .agreementTit h1{
        text-align:center;
        font-size: 16px;
        margin-bottom: 10px;
    }
</style>