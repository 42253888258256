<template>
  <div class="smain">
    <div class="shead">
      <div class="sbtxt">
        <span v-for="item of typeList" :key="item.id" @click="getTypeList(item.cids)">{{item.showName}}</span>
        <span class="clearbtn" @click="clearParm">清空</span>
      </div>
      <div class="sbanner">
         <el-carousel trigger="click" height="300px">
          <el-carousel-item v-for="item in bannerList" :key="item.fdCode">
            <div class="sb_img" @click="openHref(item.fdClickUrl)">
              <img :src="item.fdPicurl" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="slist" v-loading="!loading">
      <div v-for="item of detailList" :key="item.shopId" class="spoint">
        <div @click="openHref(item.detailUrl)">
          <img :src="item.imgUrl" alt="">
          <p>
            {{item.goodsName}}
          </p>
          <p>
            <span>￥</span>{{item.goodsPriceYuan}}
          </p>
        </div>
      </div>
    </div>
    <div
      v-loading="loading"
      v-if="listRefresh"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="store_loading">
    </div>
    <div v-if="detailList.length==0" class="emptynote">
      <p>当前类目下没有展示商品</p>
    </div>
  </div>
</template>
<script>
import fetch from "@/services/service";
export default {
  name: "Store",
  data(){
    return{
      // urlDomain: "https://www.awgroups.cn/fdAdmin",
      urlDomain: "https://www.fishsea.cn/fdAdmin",
      typeList: [],
      bannerList: [],
      cid: "",
      detailList: [],
      loading: true,
      page: 1,
      totalPage: 1,
      listRefresh: false,
    }
  },
  created(){
    this.getHeadType();
    this.getHeadBanner();
    this.getList();
    // this.getAdTxt();
  },
  mounted(){
    window.addEventListener('scroll', this.scrollFn)
  },
  watch:{
    // listRefresh(){
    //   if(!this.listRefresh){
    //     this.loading = true;
    //   }else{
    //     this.loading = false;
    //   }
    // }
  },
  methods:{
    getHeadType(){
      let url = this.urlDomain+"/api/categoryMainInfo";
      fetch.get(url).then(res=>{
        console.log(res)
        if(res.code==0){
          this.typeList = res.list;
        }
      })
    },
    getHeadBanner(){
      let url = this.urlDomain+"/api/getFd";
      fetch.get(url,{params:{fd_plat_code:8,fd_place_code:12}}).then(res=>{
        if(res.code==0){
          this.bannerList = res.fds;
        }
      })
    },
    getList(){
      this.listRefresh = true;
      let url = this.urlDomain+"/api/goodsInfo";
      let sdata ={
        page:this.page,
        limit:20,
        cids: this.cids
      };
      fetch.get(url,{params:sdata}).then(res=>{
        if(res.code==0){
          this.$nextTick(()=>{
            this.detailList = this.detailList.concat(res.page.list)
            // {...this.detailList,...res.page.list};
            this.totalPage = res.page.totalPage;
            this.listRefresh = false;
          })
        }
      })
    },
    getTypeList(id){
      this.detailList = [];
      this.cids = id;
      this.page = 1;
      this.getList();
    },
    clearParm(){
      this.cids = "",
      this.page = 1;
      this.getList();
    },
    getAdTxt(){
      let url = this.urlDomain+"/api/categoryInfo";
      console.log(url)
      fetch.get(url,{grade:0,parentId:0}).then(res=>{
        console.log(res)
      })
    },
    
    openHref(url){
      window.open(url)
    },
    scrollFn(){
      let cliHeight = document.documentElement.scrollHeight-20;
      let scrTop = document.documentElement.scrollTop+document.documentElement.clientHeight;
      if(scrTop>cliHeight && this.page<this.totalPage && this.listRefresh==false){
        this.page++;
        this.getList();
      }
    },
    
  }
}
</script>
<style lang="scss">
@import "~@/assets/css/store.scss";
</style>