<template>
	<div>
    <div class="my_artlist_box" v-loading="loading">
      <div class="my_artsearch">
        <div>
          <el-select v-model="search.classId" size="mini" @change="searchList">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item of typeList" :key="item.id" :label="item.className" :value="item.id"></el-option>
          </el-select>
          <el-input v-model="search.title" size="mini" @keyup.enter.native="searchList">
            <el-button slot="append" icon="el-icon-search" @click="searchList"></el-button>
          </el-input>
          <el-button icon="el-icon-refresh-left" circle size="mini" @click="resite"></el-button>
        </div>
      </div>
      <div v-if="tableData.length!=0">
        <ul class="my_artlist_ul">
          <li v-for="(item,index) of tableData" :key=item.id>
            <div class="my_artlist_point_img" @click="artDetail(item.id)">
              <img :src="setUrl(item.showImg)" alt="">
            </div>
            <div class="my_artlist_point" @click="artDetail(item.id)">
              <h2>{{item.title}}</h2>
              <p>
                <span>阅读{{item.pageviews}}</span>
                <span>点赞{{item.likeCount}}</span>
                <span>评论{{item.commentCount}}</span>
              </p>
              <p>
                <span :class="'st'+item.status">{{ statusTxtAry[item.status] }}</span>
                <i>{{artType(item.classId)}}</i>
              </p>
              <p>
                <span>创建时间：{{formatDate(item.addTime)}}</span>
                <span v-if="item.publishTime">发布时间：{{formatDate(item.publishTime)}}</span>
              </p>
            </div>
            <div class="my_artlist_point_btn">
              <div v-if="['0', '4'].includes(item.status)">
                <span class="el-icon-position" @click="artPub(item.id)">发布</span>
              </div>
              <!--<div v-if="item.status == '1'">
                <span class="el-icon-refresh-right" @click="artPubDown(item.id)">撤回</span>
              </div>-->
              <div v-if="['1', '3'].includes(item.status)">
                <span class="el-icon-refresh-right" @click="artPubDown(item.id)">撤回</span>
              </div>
              <div v-if="['0', '4'].includes(item.status)">
                <span class="el-icon-edit"  @click="artEdt(item.id)">编辑</span>
              </div>
              <div v-if="item.status != '1'">
                <span class="el-icon-delete"  @click="artDel(item.id)">删除</span>
              </div>
              <!-- <span @click="artDetail(item.id)">预览</span> -->
            </div>
          </li>
        </ul>
        <div class="pagestyle">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div v-else class="artnote">
        <p>还没有文章，去<a @click="gopub">创作发布</a></p>
      </div>
    </div>
		<el-dialog :visible.sync="dialogVisibleDetail" width="850px">
			<detail-box :artCont="artContent"></detail-box>
			<div slot="footer" class="dialog-footer">
				<el-button
					size="mini"
					type="primary"
					@click="dialogVisibleDetail = false"
					>关 闭</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import instance from '@/services/instance'
import moment from 'moment'
import detailBox from './detail'
export default {
	name: 'ArticleList',
	components: {
		detailBox,
	},
	data() {
		return {
      loading: true,
			tableData: [],
			multipleSelection: [],
			pageSize: 10,
			currentPage: 1,
			total: 0,
			statusTxtAry: [
				'草稿',
				'发布上架',
				'违规下架',
				'审核中',
				'审核失败',
			],
			dialogVisibleDetail: false,
      artContent: {},
      search:{
        classId: "",
        title: ""
      },
      typeList:[],
		}
  },
  computed:{
    artType(){
      return key=>{
        let tar = this.typeList.filter(item=>{
          return item.id == key
        });
        if(tar.length!=0){
          return "类别："+tar[0].className;
        }
        else{
          return ""
        }
      }
    }
  },
	created() {
    this.initList();
    this.getArtClassify();
	},
	methods: {
		initList() {
      this.loading = true;
			let sdata = {
				startPage: this.currentPage,
        pageSize: this.pageSize,
        classId: this.search.classId,
        title: this.search.title
			}
			instance
				.post('/officialWebsiteMgr/resource/article/userArticle', sdata)
				.then((res) => {
          this.loading = false;
					if (res.code == 0) {
						this.tableData = res.data.list;
						this.total = res.data.totalCount;
					}
				})
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.currentPage = 1
			this.initList()
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.initList()
		},
		artEdt(id) {
			this.$router.push({
				name: 'MyArticleCreated',
				params: {
					type: 'edit',
					id: id,
				},
			})
		},
		artPub(id) {
			this.$confirm('发布文章，是否继续？', '提示', {
				confirmButtonText: '发布',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					instance
						.post('/officialWebsiteMgr/resource/article/upStatus', {
							id: id,
							status: 3,
						})
						.then((res) => {
							if (res.code == 0) {
								this.initList()
								this.$message({
									type: 'success',
									message: '操作成功等待审核！',
								})
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发布',
					})
				})
		},
		artPubDown(id) {
			this.$confirm('文章下架，是否继续？', '提示', {
				confirmButtonText: '发布',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					instance
						.post('/officialWebsiteMgr/resource/article/upStatus', {
							id: id,
							status: 0,
						})
						.then((res) => {
							if (res.code == 0) {
								this.initList()
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '下架操作取消',
					})
				})
		},
		artDel(id) {
			this.$confirm('文章删除，是否继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'error',
			})
				.then(() => {
					instance
						.post('/officialWebsiteMgr/resource/article/del', {
							id: id,
						})
						.then((res) => {
							if (res.code == 0) {
								this.initList()
							}
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '删除操作取消',
					})
				})
		},
		artDetail(id) {
            window.open("/#/article/articleDetail/"+id, '_blank');
			/*instance
				.post('/officialWebsiteMgr/resource/article/desc', { id: id })
				.then((res) => {
					if (res.code == 0) {
						this.dialogVisibleDetail = true
						this.artContent = res.data
					}
				})*/
		},
		formatDate(time) {
			return moment(time).format('YY-MM-DD HH:mm:ss')
		},
    //图片展示
    setUrl(url) {
      // return url;
      if(url.indexOf("http")==0){
        return url;
      }else{
        return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
      }
    },
    getArtClassify(){
      instance
				.post('/officialWebsiteMgr/resource/article/classList',{})
				.then((res) => {
					if (res.code == 0) {
            this.typeList = res.data;
					}
				})
    },
    searchList(){
      this.initList();
    },
    resite(){
      this.search.title="";
      this.search.classId="";
      this.initList();
    },
    gopub(){
      this.$router.push({
				name: 'MyArticleCreated',
				params: {
					type: 'create',
				},
			})
    }
	},
}
</script>
