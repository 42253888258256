<template>
	<div>
		<el-carousel :interval="5000" arrow="always" height="300">
			<el-carousel-item>
				<div class="banner">
					<img src="~@/assets/img/banner1.jpg" alt="">
				</div>
			</el-carousel-item>
		</el-carousel>
		<div class="art_title">论坛百科</div>
		<div class="art_menu">
      		<span v-for="item of artYypeList" :key="item.id" @click="refArtList(item.id)" :class="search.classId==item.id?'art_menu_active':''">{{item.className}}</span>
		</div>
		<el-container class="content_main">
			<el-main class="content_main_context" v-if="artlist.length!=0">
				<div class="artlist">
					<ul>
						<li v-for="item in artlist" :key="item.id">
							<div class="artlist_point">
								<p
									class="artlist_title"
									@click="toDetail(item.id)"
								>
									{{ item.title }}
								</p>
								<p class="artlist_content" @click="toDetail(item.id)">
									{{ item.contentTxt }}
								</p>
								<p class="artlist_looked">
									{{ setMoment(item.publishTime) }}
									<span class="look">{{
										item.pageviews
									}}</span>
									<span class="like">{{
										item.likeCount
									}}</span>
									<span class="discount">{{
										item.commentCount
									}}</span>
								</p>
							</div>
							<div class="artlist_img" @click="toDetail(item.id)">
								<img :src="setUrl(item.showImg)" />
							</div>
						</li>
					</ul>
				</div>
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-size="10"
					layout="total,  prev, pager, next, jumper"
					:total="totalArt"
				>
				</el-pagination>
			</el-main>
      <el-main v-else>
        数据空
      </el-main>
			<el-aside class="content_main_aside">
				<div class="art_hotList">
					<h3>24小时热帖榜</h3>
					<ul>
						<li v-for="(item,index) in artNewList" :key="item.id">
							<div>{{index+1}}</div>
							<div>
								<p @click="toDetail(item.id)">{{item.title}}</p>
								<p>
									<span class="look">{{item.pageviews}}</span>
									<span class="dis">{{item.commentCount}}</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
        <div class="adlist">
          <el-carousel height="200px" indicator-position="outside">
            <el-carousel-item v-for="item in adlist" :key="item.fdCode">
              <div class="adimg">
                <a :href="item.fdClickUrl" target="_blank">
                  <img :src="item.fdPicurl">
                </a>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
			</el-aside>
		</el-container>
	</div>
</template>
<script>
import '@/assets/css/art.scss'
import fetch from '@/services/service'
import moment from 'moment'
export default {
	name: 'atricle',
	data() {
		return {
		    artlist: [],
			currentPage: 1,
			totalArt: 0,
			artNewList: [],
			adlist: [],
			artYypeList: [],
			search:{
				title: "",
				classId: ""
			}
		}
	},
	created() {
    	this.getHotList();
    	this.getAd();
    	this.getArtClassify();
	},
	methods: {
		getArtList() {
			let sdata = {
        		classId: this.search.classId,
        		title: this.search.title,
				startPage: this.currentPage,
				pageSize: 10,
			}
			fetch
				.post(
					'/officialWebsiteMgr/resource/article/indexArticle',
					sdata
				)
				.then((res) => {
					if (res.code == 0) {
						this.artlist = res.data.list
						this.totalArt = res.data.totalCount
					}
				})
		},
		setUrl(url) {
			if(url.indexOf("http")==0){
				return url;
			}else{
				return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
			}
			// return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
		},
		setMoment(time) {
			return moment(time).format('YY-MM-DD HH:mm:ss')
		},
		handleSizeChange() {},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getArtList()
		},
		toDetail(id) {
            window.open("/#/article/articleDetail/"+id, '_blank');
			/*this.$router.push({
				name: 'ArticleDetail',
				params: { id: id },
			})*/
		},
		getHotList() {
			fetch
				.post('/officialWebsiteMgr/resource/article/hotArticle',{})
				.then((res) => {
					if (res.code == 0) {
						this.artNewList = res.data
					}
				})
    	},
    	getAd(){
		  fetch.get("https://www.fishsea.cn/fdAdmin/api/getFd?fd_place_code=11&&fd_plat_code=8").then(res=>{
			if(res.code==0){
			  this.adlist = res.fds;
			}
		  })
    	},
		getArtClassify(){
		  fetch
					.post('/officialWebsiteMgr/resource/article/classList',{})
					.then((res) => {
						if (res.code == 0) {
				this.artYypeList = res.data;
				this.search.classId = res.data[0].id;
				this.getArtList();
						}
					})
		},
		refArtList(id){
			this.currentPage=1;
			this.search.classId = id;
			this.getArtList();
		},
	},
}
</script>
