<template>
	<div>
		<div class="my_header">
			<div class="header_box">
				<img src="~@/assets/logo.png" @click="goHome" />
				<!--<img src="~@/assets/logoTxt.png" @click="goHome" />-->
				<div style="float:right;margin-top:20px">
					<login-box></login-box>
				</div>
			</div>
		</div>
		<div class="my_content">
			<div class="my_conbox">
				<div class="aside">
					<el-menu
						:default-openeds="elMenuDefaultOpeneds"
						:default-active="$route.meta.subNav"
						mode="vertical"
						text-color="#475669"
						active-text-color="#1296db"
						:router="elMenuRouter"
					>
						<el-submenu v-for="(items,index) in titleSpace" :index="`${index+1}`" :key="index">
							<template slot="title">
								<i class="el-icon-location"></i>
								<span slot="title">{{items.primaryTitle}}</span>
							</template>
							<el-menu-item v-for="(item) in items.children" :key="item.routeUrl" :index="item.routeUrl"
								>{{item.title}}</el-menu-item
							>
						</el-submenu>
						<el-submenu index="1">
							<template slot="title">
								<i class="el-icon-location"></i>
								<span slot="title">我的文章</span>
							</template>
							<el-menu-item index="/mySpace/myArticleList">文章列表</el-menu-item>
							<el-menu-item index="/mySpace/myArticleCreated/create/0">创作</el-menu-item>
						</el-submenu>
						<el-submenu index="2">
							<template slot="title">
								<i class="el-icon-location"></i>
								<span slot="title">我的炫物</span>
							</template>
							<el-menu-item index="/mySpace/myPetList">炫物列表</el-menu-item>
							<el-menu-item index="/mySpace/myPetCreate/create/0">发布</el-menu-item>
						</el-submenu>
						<el-submenu index="3">
							<template slot="title">
								<i class="el-icon-location"></i>
								<span slot="title">我的足迹</span>
							</template>
							<el-menu-item index="/mySpace/footprintList">足迹列表</el-menu-item>
							<el-menu-item index="/mySpace/footCreate/create/0">发布</el-menu-item>
						</el-submenu>
						<el-submenu index="4">
							<template slot="title">
								<i class="el-icon-location"></i>
								<span slot="title">我的活动</span>
							</template>
							<el-menu-item index="/mySpace/activityList">活动列表</el-menu-item>
							<el-menu-item index="/mySpace/activityCreate/create/0">发布</el-menu-item>
						</el-submenu>
						<el-submenu index="5">
							<template slot="title">
								<i class="el-icon-location"></i>
								<span slot="title">我的</span>
							</template>

							<el-menu-item index="/mySpace/personMessage">个人信息</el-menu-item>
							<el-menu-item index="/mySpace/myShop">店铺信息</el-menu-item>
						</el-submenu>
					</el-menu>
				</div>
				<div class="my_main">
				  <router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import instance from '@/services/instance';
import fetch from "@/services/service"
import { mapState, mapActions, mapMutations } from 'vuex'
import loginBox from '@/view/login/loginType'

import {titleSpace} from './type.js'
export default {
	name: 'MySpace',
	computed: {
		...mapState('user', ['token', 'user_name', 'user_photo']),
	},
    components: {
        loginBox,
    },
	data() {
		return {
			elMenuDefaultOpeneds: ['1'],
			elMenuRouter: true,
			titleSpace:titleSpace
		}
	},
	created() {
		this.get_user_info()
	},
	methods: {
		...mapActions('user', [
			'update_user_info',
			'remove_user_info',
			'get_user_info',
		]),
		goHome() {
            this.$router.push({
                path: '/home',
            })
		},
	},
}
</script>
<style lang="sass">
@import "@/assets/css/myspace.scss"
</style>
