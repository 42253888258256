<template>
	<div class="discuss_box">
		<div class="discuss_header">
			<em>{{ disDetail.totalCount }}</em
			>条评论
		</div>
		<div class="discuss_body">
			<ul>
				<li>
					<label>
						<el-avatar
							icon="el-icon-user-solid"
							:src="defUesr"
						></el-avatar>
					</label>
					<div>
						<el-input
							placeholder="写下评论"
							v-model="mydis"
							v-if="!myDisShow"
						>
							<el-button slot="append" @click="addDis"
								>评 论</el-button
							>
						</el-input>
						<div v-if="myDisShow">
							<p class="phead">
								<span>{{ user_name }}</span>
								刚刚
								<span></span>
							</p>
							<p class="pbody">
								{{ myDisContent }}
							</p>
						</div>
					</div>
				</li>
				<li v-for="item of disList" :key="item.cmId">
					<label>
						<el-avatar
							icon="el-icon-user-solid"
							:src="getHeadshot(item.userPhoto)"
						></el-avatar>
					</label>
					<div>
						<p class="phead">
							<span>{{ item.userName }}</span
							>{{ getTimeMiss(item.addTime) }}前
							<span @click="praiseDis($event, item)">{{
								item.likeCount
							}}</span>
						</p>
						<p class="pbody">{{ item.content }}</p>
						<p class="pfun">
							<span @click="replyShow(item)">回复</span>
							<span
								@click="lookReply(item)"
								v-if="item.replyCount != 0"
								>查看回复</span
							>
							<span>{{ item.replyCount }}</span
							>条
						</p>
						<el-input
							placeholder="写下评论"
							v-model="item.reply"
							v-if="item.showRe"
						>
							<el-button slot="append" @click="replyDis(item)"
								>回 复</el-button
							>
						</el-input>
						<div class="replyList" v-if="item.showReplyList">
							<div
								v-for="ite of item.replyList"
								:key="ite.answerId"
								class="replyPoint"
							>
								<label>
									<span>
										<img
											:src="getHeadshot(ite.userPhoto)"
										/>
									</span>
								</label>
								<div>
									<p class="reply_head">
										<span>{{ ite.userName }}</span
										>to
										<span>{{ ite.answerUserName }}</span>
										<i>{{ getTimeMiss(ite.addTime) }}前</i>
										<span
											@click="praiseReply($event, ite)"
											>{{ ite.likeCount }}</span
										>
									</p>
									<p class="reply_body">{{ ite.content }}</p>
									<p class="reply_fun">
										<span @click="repeakShow(ite)"
											>回复</span
										>
									</p>
									<el-input
										placeholder="写下评论"
										v-model="ite.repeak"
										size="mini"
										v-if="ite.showRep"
									>
										<el-button
											slot="append"
											@click="repeakRep(ite, item)"
											>回 复</el-button
										>
									</el-input>
								</div>
							</div>
							<div class="replymore" v-if="item.replyMore">
								<el-link type="danger" @click="getMoreRep(item)">更多</el-link>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div
				style="height:100px;width: 100%;margin-left: 0"
				v-loading="loading"
				v-if="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)"
			></div>
			<div class="dismore" @click="moreDis" v-if="disDetail.nextPage">
				<img src="@/assets/img/more_down.png" alt="" />
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import fetch from '@/services/service'
import instance from '@/services/instance'
import moment from 'moment'
import _ from 'lodash'
export default {
	name: 'ReplyComment',
	props: {
		rtype: {
			type: Number,
			required: true,
		},
		rid: {
			type: [String, Number],
			required: true,
		},
	},
	data() {
		return {
			mydis: '',
			disDetail: {},
			disList: [],
			currPage: 1,
			pageSize: 5,
			loading: false,
			disLastId: '',
			myDisId: '',
			myDisShow: false,
			myDisContent: '',
		}
	},
	computed: {
		...mapGetters('user', ['isLogin']),
		...mapState('user', ['token', 'user_name', 'user_photo']),
		defUesr() {
			if (this.isLogin) {
				return this.user_photo
			} else {
				return 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
			}
		},
	},
	created() {
		this.getDisList()
	},
	watch: {
    rid(nVal,oVal){
      this.disList = [];
      this.getDisList()
    }
  },
	methods: {
		//评论列表
		getDisList() {
			let sdata = {
				type: this.rtype,
				pId: this.rid,
				startPage: this.currPage,
				pageSize: this.pageSize,
				lastId: this.disLastId,
				outIds: this.myDisId,
			}
			this.loading = true
			fetch
				.post('/officialWebsiteMgr/resource/comment/list', sdata)
				.then((res) => {
					if (res.code == 0) {
						this.loading = false
						this.disDetail = res.data
						this.disList = this.disList.concat(res.data.list)
						this.disLastId =
							this.disList.length == 0
								? ''
								: _.last(this.disList).cmId
					}
				})
		},
		//评论更多
		moreDis() {
			this.currPage += this.currPage
			this.getDisList()
		},
		//imgUrl
		getHeadshot(url) {
			return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
		},
		//格式时间显示差
		getTimeMiss(time) {
			let mis_year = moment().diff(moment(time), 'year')
			let mis_day = moment().diff(moment(time), 'day')
			let mis_hour = moment().diff(moment(time), 'hour')
			let mis_minute = moment().diff(moment(time), 'minute')
			let mis_second = moment().diff(moment(time), 'second')
			let ary = [
				{ name: '年', timeMis: mis_year },
				{ name: '天', timeMis: mis_day },
				{ name: '小时', timeMis: mis_hour },
				{ name: '分', timeMis: mis_minute },
				{ name: '秒', timeMis: mis_second },
			]
			let getMis = _.find(ary, function(item) {
				return item.timeMis != 0
            })
            if(!getMis){
                return "刚刚";
            }else{
                return getMis.timeMis + getMis.name
            }
		},
		//添加评论
		addDis() {
			let sdata = {
				type: this.rtype,
				pId: this.$route.params.id,
				content: this.mydis,
			}
			instance
				.post('/officialWebsiteMgr/resource/comment/add', sdata)
				.then((res) => {
					console.log(res)
					if (res.code == 0) {
						this.$message.success(res.msg);
						this.myDisId = res.data;
						this.myDisShow = true;
						this.myDisContent = this.mydis;
                        this.disDetail.totalCount++;
                        this.mydis = "";
					} else {
						this.$message.error(res.msg)
					}
				})
		},
		//评论回复input展示
		replyShow(ite) {
			if (this.isLogin) {
				this.$set(ite, 'showRe', !ite.showRe)
			} else {
				this.$message.error('若回复请先登录！')
				this.$set(ite, 'showRe', false)
            }
		},
		//评论回复
		replyDis(ite) {
			let sdata = {
				cmId: ite.cmId,
				content: ite.reply,
				answerUserId: ite.userId,
            }
            // console.log(ite)
            // return false;
			instance
				.post('/officialWebsiteMgr/resource/comment/reply/add', sdata)
				.then((res) => {
					if (res.code == 0) {
                        this.$message.success('回复成功！');
                        ite.reply = "";
						this.$set(ite, 'showRe', !ite.showRe);
						this.isMoreData(ite,res.data);
					} else {
						this.$message.error(res.msg)
					}
				})
        },
        //判断回复页 如果展开 有more不动  没有自定义数据添加list ，没有展开不管
        isMoreData(ite,data){
            this.$set(ite, 'replyCount', ite.replyCount+=1);
            if(!ite.replyMore){
                ite.replyList.push(data);
                // this.$set(ite, 'replyList ', _.concat(ite.replyList,data));
            }
        },
        //查看回复展示
		lookReply(ite) {
            this.$set(ite, 'showReplyList', !ite.showReplyList);
            if(!ite.showReplyListInit){
                this.$set(ite, 'showReplyListInit', true);
                this.getReplyList(ite);
            }
		},
		getReplyList(ite) {
			if (!ite.showReplyList) {
				return ''
			}
			let sdata = {
				cmId: ite.cmId,
				startPage: ite.rStartPage,
				pageSize: ite.rPageSize,
			}
			fetch
				.post('/officialWebsiteMgr/resource/comment/reply/list', sdata)
				.then((res) => {
					if (res.code == 0) {
                        console.log(ite)
                        this.$set(ite, 'replyList', _.concat(ite.replyList,res.data.list));
                        this.$set(ite, 'replyCount', res.data.totalCount);
                        this.$set(ite, 'replyMore', res.data.nextPage);
                        if(res.data.nextPage){
                            this.$set(ite, 'rStartPage', ite.rStartPage+=1);
                        }
					}
				})
		},
		
		//repeak回复input 展示
		repeakShow(ite) {
			if (this.isLogin) {
				this.$set(ite, 'showRep', !ite.showRep)
			} else {
				this.$message.error('若回复请先登录！')
				this.$set(ite, 'showRep', false)
            }
        },
        //repeak回复
        repeakRep(ite, par) {
			let sdata = {
				cmId: ite.cmId,
				userId: ite.userId,
				content: ite.repeak,
				answerUserId: ite.userId,
			}
			instance
				.post('/officialWebsiteMgr/resource/comment/reply/add', sdata)
				.then((res) => {
					if (res.code == 0) {
						this.$message.success('回复成功！');
						this.isMoreData(par);
                        this.$set(ite, 'showRep', !ite.showRep);
                        ite.repeak = "";
					} else {
						this.$message.error(res.msg)
					}
				})
        },
        //更多回复
        getMoreRep(item){
            this.getReplyList(item);
        },
		//回复点赞
		praiseReply(e, ite) {
			let sdata = {
				type: 4,
				pId: ite.answerId,
				goodFlag: 1,
			}
			// officialWebsiteMgr/resource/like/upDown
			instance
				.post('/officialWebsiteMgr/resource/like/upDown', sdata)
				.then((res) => {
					if (res.code == 0) {
						e.target.className = 'on'
						ite.likeCount = res.data
					}
				})
		},
		//评论点赞
		praiseDis(e, ite) {
			let sdata = {
				type: 3,
				pId: ite.cmId,
				goodFlag: 1,
			}
			instance
				.post('/officialWebsiteMgr/resource/like/upDown', sdata)
				.then((res) => {
					console.log(res)
					if (res.code == 0) {
						e.target.className = 'on'
						ite.likeCount = res.data
					}
				})
		},

		
	},
}
</script>
<style lang="scss">
.discuss_box {
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid #C5C5C5;
    padding: 20px;
}
.discuss_header {
	font-size: 12px;
	color: #657180;
	height: 20px;
	line-height: 20px;
	margin-bottom: 20px;
}
.discuss_header em {
	font: 22px/24px Georgia;
	color: #f85959;
}
.artgood {
	text-align: right;
}
.discuss_body {
	ul {
		padding: 0;
	}
	li {
		border-bottom: 1px solid #f2f2f2;
		padding: 14px 0;
		label {
			float: left;
			width: 40px;
			height: 40px;
			.el-avatar > img {
				height: 44px;
				width: 44px;
				margin-top: -2px;
				margin-left: -2px;
			}
		}
		> div {
			margin-left: 50px;
			font-size: 14px;
			p {
				margin: 0;
			}
			p.phead {
				height: 25px;
				line-height: 20px;
				color: #777;
				> span {
					color: #1296db;
					margin-right: 20px;
				}
				span:last-child {
					margin-right: 10px;
					float: right;
					padding-right: 20px;
					background: url('~@/assets/img/praise_off.png') no-repeat
						right center;
					color: #777;
					transition: 1s;
					-moz-transition: 1s; /* Firefox 4 */
					-webkit-transition: 1s; /* Safari and Chrome */
					-o-transition: 1s;
					cursor: pointer;
				}
				span.on {
					color: #f85959;
					background: url('~@/assets/img/praise_on.png') no-repeat
						right center;
				}
			}
			p.pbody {
				line-height: 22px;
			}
			p.pfun {
				height: 20px;
				margin: 10px 0;
				span {
					cursor: pointer;
					margin-right: 20px;
				}
				span:first-child {
					color: #1296db;
				}
				span:last-child {
					color: #f85959;
					margin-right: 0;
				}
			}
			.replyList {
				margin-top: 10px;
				.replymore {
					text-align: center;
				}
			}
			.replyPoint {
				border-bottom: 1px dotted #e2e2e2;
				padding: 5px 0;
				> label {
					float: left;
					width: 30px;
					height: 30px;
					border-radius: 15px;
					overflow: hidden;
					img {
						height: 34px;
						width: 34px;
						margin-top: -2px;
						margin-left: -2px;
					}
				}
				> div {
					margin-left: 40px;
					font-size: 12px;
					.reply_head {
						height: 30px;
						line-height: 30px;
						font-size: 12px;
						span {
							color: #1296db;
							margin-right: 10px;
						}
						span:nth-child(2) {
							margin-left: 10px;
						}
						i {
							color: #777;
						}
						span:last-child {
							margin-right: 10px;
							float: right;
							padding-right: 20px;
							background: url('~@/assets/img/praise_off.png')
								no-repeat right center;
							color: #777;
							transition: 1s;
							-moz-transition: 1s; /* Firefox 4 */
							-webkit-transition: 1s; /* Safari and Chrome */
							-o-transition: 1s;
							cursor: pointer;
						}
						span.on {
							color: #f85959;
							background: url('~@/assets/img/praise_on.png')
								no-repeat right center;
						}
					}
					.reply_body {
					}
					.reply_fun {
						color: #1296db;
						cursor: pointer;
					}
				}
			}
		}
	}
	.dismore {
		height: 30px;
		text-align: center;
		width: 200px;
		margin: 0 auto;
		cursor: pointer;
	}
	.dismore img {
		width: 30px;
		animation: more 2s linear infinite alternate;
		-moz-animation: more 2s linear infinite alternate; /* Firefox */
		-webkit-animation: more 1s linear infinite alternate; /* Safari 和 Chrome */
		-o-animation: more 2s linear infinite alternate; /* Opera */
	}
	@keyframes more {
		0% {
			height: 20px;
		}
		100% {
			height: 30px;
		}
	}
	@-webkit-keyframes more /* Safari 和 Chrome */ {
		0% {
			height: 20px;
		}
		100% {
			height: 30px;
		}
	}
	@-o-keyframes more /* Opera */ {
		0% {
			height: 20px;
		}
		100% {
			height: 30px;
		}
	}
	@-moz-keyframes more /* Firefox */ {
		0% {
			height: 20px;
		}
		100% {
			height: 30px;
		}
	}
}
</style>
