<template>
    <div>
        <div class="pet-menu">
            <div class="pet-menu-logo">
                <img src="@/assets/img/wang.png" alt="">
                <b>汪星人</b>
            </div>
            <div class="pet-menu-kinds">
                <span v-for="item in petNameList" :key="item.id">{{item.name}}</span>
            </div>
        </div>
        <el-container>
            <el-main class="ss">
                <div class="pet-box">
                    <div class="quality-pet-box">
                        <ul>
                            <li v-for="item in quaList" :key="item.id" @click="openDetail(item.id)">
                                <img :src="item.img" alt="">
                                <p>{{item.txt}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="hot-shop">
                        <h3>推荐商家<span></span></h3>
                        <ul class="shop-list">
                            <li v-for="item in shopList" :key="item.id">
                                <h3 @click="goshop(item.id)">{{item.name}}</h3>
                                <div>
                                    <p>{{item.info}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </el-main>
            <el-aside width="300px">
                <h3>热门汪星人</h3>
                <ul class="hot-pet-box">
                    <li v-for="(item) in hotwang" :key="item.id">
                        <img :src="item.img" alt="">
                        <div>
                            <span>{{item.name}}</span>
                            <p>市价： ￥{{item.price}}+</p>
                        </div>
                    </li>
                </ul>
            </el-aside>
        </el-container>
    </div>
</template>
<script>
export default {
    name: "wang",
    data(){
        return{
            petNameList: [
                {id: 1,name: "金毛"},
                {id: 2,name: "哈士奇"},
                {id: 3,name: "泰迪犬"},
                {id: 4,name: "阿拉斯加雪橇"},
                {id: 5,name: "贵宾犬"},
                {id: 6,name: "德牧"},
            ],
            quaList:[
                {id: 1, img: require("@/assets/img/3.jpg"), txt: "小苍店金牌苏格兰牧羊犬"},
                {id: 2, img: require("@/assets/img/3.jpg"), txt: "小苍店金牌苏格兰牧羊犬"},
            ],
            shopList: [
                {id: 1, name: "光荣家猫咪专卖", info: "我家店有的伯曼猫比较馋，人吃什么它也想吃什么，你喂它就吃。但如果长期这样做的话，会"},
                {id: 2, name: "光荣家猫咪专卖", info: "我家店有的伯曼猫比较馋，人吃什么它也想吃什么，你喂它就吃。但如果长期这样做的话，会"},
                {id: 3, name: "光荣家猫咪专卖", info: "我家店有的伯曼猫比较馋，人吃什么它也想吃什么，你喂它就吃。但如果长期这样做的话，会"},
                {id: 4, name: "光荣家猫咪专卖", info: "我家店有的伯曼猫比较馋，人吃什么它也想吃什么，你喂它就吃。但如果长期这样做的话，会"},
                {id: 5, name: "光荣家猫咪专卖", info: "我家店有的伯曼猫比较馋，人吃什么它也想吃什么，你喂它就吃。但如果长期这样做的话，会"},
            ],
            hotwang:[
                {id: 1, name: "德国牧羊犬", img: require("@/assets/img/4.jpg"), price: 1000},
                {id: 2, name: "德国牧羊犬", img: require("@/assets/img/5.jpg"), price: 1000},
                {id: 3, name: "德国牧羊犬", img: require("@/assets/img/4.jpg"), price: 1000},
                {id: 4, name: "德国牧羊犬", img: require("@/assets/img/5.jpg"), price: 1000},
                {id: 5, name: "德国牧羊犬", img: require("@/assets/img/4.jpg"), price: 1000}
            ]
        }
    },
    methods:{
        openDetail(id){
            console.log(id);
            this.$router.push({
                name: "WangDetail",
                params: {id:id}
            })
        },
        goshop(id){
            this.$router.push({
                name: "Shop",
                params: {id: id}
            })
        }
    }
}
</script>
<style scope>
.ss{
    padding: 0;
    margin-right:30px;
}
.pet-box{
    display: flex;
}
.quality-pet-box{
    width:  360px;
    float: left;
}
.quality-pet-box ul,.quality-pet-box ul li{
    padding: 0;
    margin: 0;
}
.quality-pet-box ul li{
    margin-bottom: 10px;
    position: relative;
}
.quality-pet-box ul li img{
    width: 360px;
    height: 270px;
    border-radius: 3px;
}
.quality-pet-box ul li p{
    width: 300px;
    font-size: 16px;
    color: #000;
    background: rgba(255,255,255,0.7);
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 30px;
    height: 40px;
    line-height: 40px;
}
.hot-shop{
    flex: 1;
    margin-left: 30px;
}
.hot-shop>h3{
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    margin-bottom: 20px;
    font-weight: normal;
}
.hot-shop>h3>span{
    padding: 10px;
    background: url("~@/assets/img/hot.png") no-repeat center;
    background-size: 20px 20px;
}
.shop-list{
    margin: 0;
    padding: 0;
}
.shop-list li{
    padding-left: 20px;
    padding-bottom: 15px;
    border-bottom: 1px dotted #a3a3a3;
    margin-bottom: 15px;
}
.shop-list li>h3{
    padding: 0;
    margin: 0;
    height: 20px;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
}
.shop-list li>div>p{
    color: #888;
    line-height: 22px;
    padding: 0;
    margin: 0;
}
.hot-pet-box{
    padding: 0;
    margin: 0;
    margin-left: 20px;
}
.hot-pet-box li{
    margin-bottom: 10px;
    display: flex;
}
.hot-pet-box li img{
    float: left;
    height: 100px;
    width: 100px;
}
.hot-pet-box li>div{
    padding: 0 10px;
}
.hot-pet-box li>div span{
    font-size: 14px;
}
.hot-pet-box li>div p{
    margin: 0;
    color: #888;
}
</style>
