<template>
    <div>
        <el-carousel :interval="5000" arrow="always" height="300">
            <el-carousel-item>
                <div class="banner">
                    <img src="~@/assets/img/banner1.jpg" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="cont">
            <h2>热门活动<p></p></h2>
            <div class="activity">
                <!--<div class="activityMin" v-for="(item,index) in activityList" :key="index" @click="activityDetail(item.activityId)">
                    <img :src="setUrl(item.showImg)" alt="">
                    <p>{{item.activityName}}</p>
                </div>-->
                <div class="activityMin" @click="activityDetail(activityList[0].activityId)">
                    <img :src="setUrl(activityList[0].showImg)" alt="">
                    <p>{{activityList[0].activityName}}</p>
                </div>
            </div>
        </div>
        <div class="main">
            <h2>活动专区<p></p></h2>
            <el-container class="content_main">
                <el-main class="content_main_context" v-if="list.length!=0">
                    <div class="artlist">
                        <ul>
                            <li v-for="item in list" :key="item.activityId" @click="activityDetail(item.activityId)">
                                <div class="artlist_img">
                                    <img :src="setUrl(item.showImg)" />
                                </div>
                                <div class="artlist_point">
                                    <p class="artlist_title">
                                        {{ item.activityName }}
                                    </p>
                                    <p class="artlist_content">
                                        {{ item.activityDesc }}
                                    </p>
                                    <p class="artlist_looked">
                                        开始和结束时间：{{ item.activityTimeStr}} ~ {{item.activityEndTimeStr}}
                                        <span>创建人：{{item.activityUser}}</span>
                                        <span>状态：{{activityStatus[item.activityStatus]}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <el-pagination
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-size="10"
                            layout="total,  prev, pager, next, jumper"
                            :total="totalArt"
                    >
                    </el-pagination>
                </el-main>
                <el-main v-else>
                    数据空
                </el-main>
                <div class="right">
                    <div class="rightCode">
                        <p>
                            关注我们<br>
                            "<span>鱼海科技</span>"
                            公众号
                        </p>
                        <img src="@/assets/img/code.jpg" alt="">
                    </div>
                    <div class="advertising"></div>
                </div>
            </el-container>

        </div>
    </div>
</template>

<script>
import '@/assets/css/art.scss'
import fetch from '@/services/service'
import moment from 'moment'

export default {
    name: "Activity",
    data() {
        return {
            activityList:[],
            list: [],
            currentPage: 1,
            totalArt: 0,
            activityStatus:["未发布","未开始","已结束","下架(系统强制)","活动中"],
        }
    },
    created() {
        this.getActivity();//热门活动
        this.getList();//活动列表
    },
    methods:{
        setUrl(url) {
            if(url.indexOf("http")==0){
                return url;
            }else{
                return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
            }
        },
        getActivity(){
            //活动
            var sdata = {
                startPage: 1,
                pageSize:3,
            }
            fetch
                .post('/officialWebsiteMgr/resource/activity/indexList',sdata)
                .then((res) => {
                    if (res.code == 0) {
                        this.activityList=res.data.list;
                    }
                })
        },
        getList() {
            let sdata = {
                startPage: this.currentPage,
                showNum: 10,
            }
            fetch
                .post(
                    '/officialWebsiteMgr/resource/activity/indexList',
                    sdata
                )
                .then((res) => {
                    if (res.code == 0) {
                        this.list = res.data.list;
                        console.log( this.list)
                        this.totalArt = res.data.totalCount
                    }
                })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getList()
        },
        activityDetail(id) {
            window.open("/#/activityDetail/"+id, '_blank');
            /*this.$router.push({
                name: 'activityDetail',
                params: { id: id },
            })*/
        },
    }
}
</script>

<style scoped>
.cont{
    width:1200px;
    margin:0 auto;
}
.cont>h2,.main>h2{
    font-size: 30px;
    color:#333333;
    text-align: center;
    margin:40px 0 20px;
    font-weight: normal;
}
.cont>h2 p,.main>h2 p{
    width:75px;
    height:3px;
    background:#4cb8df;
    margin:10px auto 0;
}
.activity{
    overflow: hidden;
}
.activityMin{
    position: relative;
    float:left;
    margin:0 24px 24px 0;
    width:32%;
    cursor: pointer;
}
.activityMin:nth-child(3n){
    margin-right:0;
}
.activityMin img{
    width:100%;
    height:290px;
    border-radius: 10px;
}
.activityMin p{
    position: absolute;
    bottom:20px;
    left:0;
    right:0;
    margin:auto;
    background:rgba(255,255,255,0.5);
    height:40px;
    text-align: center;
    color:#2e3235;
    font-size: 18px;
    line-height:40px;
    width:80%;
}
.artlist_point{
    margin-left:20px;
}
.right{
    width:300px;
}
.rightCode{
    background:#fff;
    border-radius:8px;
    padding:20px 15px;
    font-size:16px;
    display: flex;
    display: -webkit-flex;
}
.rightCode p{
    line-height: 40px;
}
.rightCode p span{
    color:#127be8;
}
.rightCode img{
    width:85px;
    height:85px;
    margin-left:40px;
}
.advertising{
    background:#ccc;
    width:300px;
    height:300px;
    margin-top:20px;
    border-radius:8px;
}
</style>