import axios from "axios";

axios.interceptors.request.use(config=>{
    return config
},error=>{
    console.log(error)
});
axios.interceptors.response.use(res=>{
    if(res.status===200){
        return Promise.resolve(res.data)
    }else{
        return Promise.reject(res)
    }
})
export default axios;