// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/hot.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ss{padding:0;margin-right:30px}.pet-box{display:-webkit-box;display:-ms-flexbox;display:flex}.quality-pet-box{width:360px;float:left}.quality-pet-box ul,.quality-pet-box ul li{padding:0;margin:0}.quality-pet-box ul li{margin-bottom:10px;position:relative}.quality-pet-box ul li img{width:360px;height:270px;border-radius:3px}.quality-pet-box ul li p{width:300px;font-size:16px;color:#000;background:hsla(0,0%,100%,.7);position:absolute;bottom:20px;text-align:center;left:30px;height:40px;line-height:40px}.hot-shop{-webkit-box-flex:1;-ms-flex:1;flex:1;margin-left:30px}.hot-shop>h3{font-size:20px;line-height:20px;height:20px;margin-bottom:20px;font-weight:400}.hot-shop>h3>span{padding:10px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:20px 20px}.shop-list{margin:0;padding:0}.shop-list li{padding-left:20px;padding-bottom:15px;border-bottom:1px dotted #a3a3a3;margin-bottom:15px}.shop-list li>h3{padding:0;margin:0;height:20px;font-size:16px;font-weight:400;margin-bottom:10px}.shop-list li>div>p{color:#888;line-height:22px;padding:0;margin:0}.hot-pet-box{padding:0;margin:0;margin-left:20px}.hot-pet-box li{margin-bottom:10px;display:-webkit-box;display:-ms-flexbox;display:flex}.hot-pet-box li img{float:left;height:100px;width:100px}.hot-pet-box li>div{padding:0 10px}.hot-pet-box li>div span{font-size:14px}.hot-pet-box li>div p{margin:0;color:#888}", ""]);
// Exports
module.exports = exports;
