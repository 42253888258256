import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

import Home from '@/view/home.vue'
import Index from '@/view/index.vue'
import Login from '@/view/login/login.vue'
import Register from '@/view/login/register.vue'
import Agreement from '@/view/login/agreement.vue'
import JumpWx from '@/view/login/jumpWx.vue'
import JumpLogin from '@/view/login/jumpLogin.vue'
import Phone from '@/view/login/phone.vue'
import Articl from '@/view/article/article.vue'
import ArticleDetail from '@/view/article/articleDetail.vue'

import CoolThings from "@/view/cool/index.vue";
import CoolList from "@/view/cool/list.vue";
import CoolDetail from "@/view/cool/detail.vue";

import Store from "@/view/store/index";

import Wang from '@/view/wang/wang.vue'
import WangDetail from '@/view/wang/wangDetail.vue'

import Shop from '@/view/shop/shop.vue'

import MySpace from '@/view/mySpace/index';
import MyArticleList from '@/view/mySpace/article/list';
import MyArticleCreated from '@/view/mySpace/article/created';
import PersonMessage from '@/view/mySpace/message/person';

import MyPetList from "@/view/mySpace/pet/list";
import MyPetCreate from "@/view/mySpace/pet/created";
// import MyPetDetail from "@/view/mySpace/pet/detail";

import Search from "@/view/search/search";

import Activity from '@/view/activity/activity';

import PetList from "@/view/mySpace/pet/list";

Vue.use(Router)
let isLogin = () => {
	return store.getters['user/isLogin']
}

const routes = [
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		children: [
			{
                path: '/',
                name: 'Index',
                component: Index,
                meta: {
                    nav: 'home',
                    isLogin: false,
                },
			},
            {
                path: '/login',
                name: 'login',
                component: Login,
            },
            {
                path: '/register',
                name: 'register',
                component: Register,
            },
            {
                path: '/agreement',
                name: 'agreement',
                component: Agreement,
            },
            {
                path: '/jumpLogin/:code/:msg',
                name: 'jumpLogin',
                component: JumpLogin,
            },
            {
                path: '/jumpWx/:code/:msg',
                name: 'jumpWx',
                component: JumpWx,
            },
            {
                path: '/phone',
                name: 'phone',
                component: Phone,
            },
			{
				path: '/article',
				name: 'Article',
				component: Articl,
				meta: {
					nav: 'article',
					isLogin: false,
				},
			},
			{
				path: '/article/articleDetail/:id',
				name: 'ArticleDetail',
				component: ArticleDetail,
				meta: {
					nav: 'article',
				},
      		},
		  	{
				path: "/cool",
				name: "CoolThings",
				component: CoolThings,
				meta: {
						nav: 'cool',
				},
		  	},
			{
				path: "/coolList/:type/:id",
				name: "CoolList",
				component: CoolList,
				meta: {
							nav: 'cool',
				},
			},
		  	{
				path: "/coolDetail/:id",
				name: "CoolDetail",
				component: CoolDetail,
				meta: {
							nav: 'cool',
				},
		  	},
		  	{
				path: '/wang',
				name: 'Wang',
				component: Wang,
				meta: {
					nav: '/wang',
				},
			},
			{
				path: '/wang/detail/:id',
				name: 'WangDetail',
				component: WangDetail,
				meta: {
					nav: '/wang',
				},
			},
			{
				path: '/shop',
				name: 'Shop',
				component: Shop,
				meta: {
					nav: '/home',
				},
      		},
			{
				path: "/store",
				name: 'Store',
				component: Store,
				meta: {
				  nav: "store"
				}
			},
            {
                path: "/activity",
                name: 'Activity',
                component: Activity,
                meta: {
                    nav: "activity"
                }
            },
			{
                path: "/activityDetail/:id",
                name: 'activityDetail',
                component: ()=>import('@/view/activity/activityDetail'),
                meta: {
                    nav: "activity"
                }
			},
			{
				path: "/footprint",
				name: "footprint",
				component: ()=>import('@/view/footprint/index'),
				meta: {
						nav: 'footprint',
				},
		  	},
            {
                path: "/footDetail/:id",
                name: "footDetail",
                component: ()=>import('@/view/footprint/detail'),
                meta: {
                    nav: 'footprint',
                },
            },
		],
	},
	{
		path: '/mySpace',
		name: 'MySpace',
		component: MySpace,
		meta: {
			isLogin: true,
		},
		children: [
			{
				path: '/',
				redirect: 'myArticleList',
			},
			{
				path: 'myArticleList',
				name: 'MyArticleList',
				component: MyArticleList,
				meta: {
					subNav: 'myArticleList',
				},
			},
			{
				path: 'myArticleCreated/:type/:id',
				name: 'MyArticleCreated',
				component: MyArticleCreated,
				meta: {
					subNav: 'myArticleCreated',
				},
			},
			{
				path: 'personMessage',
				name: 'PersonMessage',
				component: PersonMessage,
				meta: {
					subNav: 'personMessage',
				},
      		},
      		{
				path: 'myPetList',
				name: 'MyPetList',
				component: MyPetList,
				meta: {
					subNav: 'myPetList',
				},
      		},
      		{
				path: 'myPetCreate/:type/:id',
				name: 'MyPetCreate',
				component: MyPetCreate,
				meta: {
					subNav: 'myPetList',
				},
			 },
			{
				path: 'footprintList',
				name: 'footprintList',
				component: ()=>import('@/view/mySpace/footprint/list'),
				meta: {
					subNav: 'footprintList',
				},
			},
			{
				path: 'footCreate/:type/:id',
				name: 'footCreate',
				component: ()=>import('@/view/mySpace/footprint/created'),
				meta: {
					subNav: 'footprintList',
				},
      		},
            {
                path: 'activityList',
                name: 'activityList',
                component: ()=>import('@/view/mySpace/activity/list'),
                meta: {
                    subNav: 'activityList',
                },
            },
            {
                path: 'activityCreate/:type/:id',
                name: 'activityCreate',
                component: ()=>import('@/view/mySpace/activity/created'),
                meta: {
                    subNav: 'activityList',
                },
            },
            {
                path: 'record/:id',
                name: 'record',
                component: ()=>import('@/view/mySpace/activity/record'),
                meta: {
                    subNav: 'activityList',
                },
            },
            {
                path: 'myShop',
                name: 'myShop',
                component: ()=>import('@/view/mySpace/shop/myShop'),
                meta: {
                    subNav: 'myShop',
                },
            },
            {
                path: 'shop',
                name: 'shop',
                component: ()=>import('@/view/mySpace/shop/shop'),
                meta: {
                    subNav: 'myShop',
                },
            },
            {
                path: 'editShop/:id',
                name: 'editShop',
                component: ()=>import('@/view/mySpace/shop/editShop'),
                meta: {
                    subNav: 'myShop',
                },
            },
		],
	},
    {
        path: '/search/:txt',
        name: 'Search',
        component: Search,
    },
]

var router = new Router({
	routes,
})
router.beforeEach((to, from, next) => {
	// console.log(to)
	// console.log(store)
	// console.log(isLogin())
	// if (isLogin()) {
	//   next(false);
	// } else {
	//   next(false);
	// }
	next()
})
export default router
