<template>
	<div class="artc_box">
		<div class="artc_form">
			<div class="art_editor_toolbar">
        <div :class="editorBarFlool?'editorBarFixed':''">
				  <div id="editorToolBar" ref="editorBar" ></div>
        </div>
			</div>

			<el-input
				v-model.trim="artform.title"
				placeholder="请输入文章标题(5-30汉字)"
				maxlength="30"
				show-word-limit
			></el-input>

			<div id="editorCont" class="art_editor_cont"></div>

			<div class="artc_cover">
				<span>封面图：</span>
				<div class="artc_img">
					<div @click="openCoverDialog">
            <img :src="setUrl(cover)" v-if="cover!=''">
						<b v-else>+</b>
					</div>
					<p>优质的封面有利于展示，格式支持JPEG、PNG</p>
				</div>
			</div>
      <div class="artc_type">
				<span>文章分类：</span>
				<el-select v-model="artType">
          <el-option v-for="item of artTypeList" :key="item.id" :value="item.id" :label="item.className">
          </el-option>
        </el-select>
			</div>
		</div>
    <div class="artc_but">
      <el-button @click="goBackList">返回</el-button>
      <el-button @click="clearFrom">清空</el-button>
      <el-button @click="saveArt" type="primary">保存</el-button>
    </div>
		<el-dialog
			:visible.sync="dialogCover"
			width="808px"
			class="artc_dialog"
      :close-on-press-escape=false
      :close-on-click-modal=false
		>
			<div slot="title" class="artc_dia_header">
				<span
					@click="changeCoverSour(1)"
					:class="dialogShowIndex == 1 ? 'active' : ''"
					>正文图片</span
				>
				<span
					@click="changeCoverSour(2)"
					:class="dialogShowIndex == 2 ? 'active' : ''"
					>上传图片</span
				>
			</div>
			<div v-show="dialogShowIndex == 1">
				<div class="artc_dia_imglist">
					<div v-for="item of contImageList" :key="item.index" @click="changeCover(item)" :class="coverchoice.index==item.index&&coverchoice.status?'cover_ind':''">
						<img :src="item.src" alt="" />
            <div v-if="coverchoice.index==item.index &&coverchoice.status" class="cover_cur"><span class="el-icon-success"></span></div>
					</div>
				</div>
			</div>
			<div v-show="dialogShowIndex == 2">
				<el-upload
					class="upload_cover_btn"
					action="/officialWebsiteMgr/resource/file/addImage"
					:on-success="handleSuccess"
          :show-file-list=false
				>
					<el-button size="small" type="primary">点击上传</el-button>
					<div slot="tip" class="el-upload__tip">
						只能上传jpg/png文件
					</div>
				</el-upload>
        <div class="artc_dia_imglist" v-if="uploadFileSrc.length!=0">
          <div v-for="item of uploadFileSrc" :key="item.index" @click="changeCover(item)" :class="coverchoice.index==item.index&&coverchoice.status?'cover_ind':''">
            <img :src="setUrl(item.src)" alt="" />
            <div v-if="coverchoice.index==item.index &&coverchoice.status" class="cover_cur"><span class="el-icon-success"></span></div>
          </div>
        </div>
			</div>
      <div slot="footer">
        <el-button @click="dialogCover = false">取 消</el-button>
        <el-button type="primary" @click="subCover">确 定</el-button>
      </div>
		</el-dialog>

	</div>
</template>
<script>
// import Editor from '@/components/editor'
import instance from '@/services/instance';
import E from 'wangeditor';
import _ from 'lodash';
import  'wangeditor/release/wangEditor.css';

export default {
	name: 'ArticleCreated',
	components: {
		// Editor,
	},
	data() {
		return {
			artform: {
				title: '',
			},
			dialogCover: false, //封面弹窗
			contImageList: [], //弹窗正文图片列表
			dialogShowIndex: 1, //弹窗选项卡
      uploadFileSrc: [], //图片上传
      coverchoice: {},  //封面图选择
      cover: "",  //封面图
      status: "",
      editorBarFlool: false,  //悬浮
      artType: "",  //文章种类
      artTypeList: [],
		}
	},
	created() {
		// if (this.status == 'edit') {
		// 	this.initFrom()
    // }
    this.getArtClassify();
  },
  computed:{
    curStatus(){
      return this.status;
    },

  },
  watch:{
    $route(){
      this.status = this.$route.params.type;
    },
    curStatus(){
      if(this.curStatus=="edit"){
        this.initFrom()
      }else{
        this.clearFrom();
      }
    }
  },
	mounted() {
    this.initEditor();
    this.status = this.$route.params.type;
    window.addEventListener('scroll', this.scrollFn)
	},
	methods: {
		//编辑器初始化
		initEditor() {
			// this.editor = new E(this.$refs.editor)
			this.editor = new E('#editorToolBar', '#editorCont')
			this.editor.customConfig.menus = [
				'head', // 标题
				'bold', // 粗体
				'fontSize', // 字号
				'fontName', // 字体
				'italic', // 斜体
				'strikeThrough', // 删除线
				'foreColor', // 文字颜色
				'backColor', // 背景颜色
				'list', // 列表
				'justify',
				'image',
				'link',
				'undo',
				'redo',
			]
			this.editor.customConfig.zIndex = 100
			this.editor.customConfig.pasteFilterStyle = true //关闭粘贴样式的过滤

			this.editor.customConfig.uploadImgShowBase64 = true // base 64 存储图片
			this.editor.customConfig.uploadImgServer =
				'/officialWebsiteMgr/resource/file/addImage' //'http://otp.cdinfotech.top/file/upload_images'// 配置服务器端地址
			this.editor.customConfig.uploadFileName = 'file' //自定义fileName
			this.editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024 //图片大小
			this.editor.customConfig.uploadImgMaxLength = 3 //一次传几张
			this.editor.customConfig.uploadImgTimeout = 3000 //定义超时时间
			this.editor.customConfig.uploadImgHooks = {
				customInsert: function(insertImg, result, editor) {
					var url ='/officialWebsiteMgr/resource/file/getImage?fileName=' +result.data
					insertImg(url)
				},
			}
			// this.editor.customConfig.onchange = (html) => {
			// 	this.getimgList(html)
			// }
			this.editor.create()
		},
		//得到编辑器中图片列表
		getimgList(html) {
			let imglist = [];
			jQuery.each(jQuery(html).find('img'), function(index, item) {
				imglist.push({ index: _.uniqueId("img_"), src: jQuery(item).attr('src') ,status: false});
			})
			this.contImageList = imglist;
		},
		//弹窗选项卡
		changeCoverSour(type) {
			this.dialogShowIndex = type;
    },
    //上传成功
		handleSuccess(res) {
			if (res.code == 0) {
        this.uploadFileSrc.push({ index: _.uniqueId("img_"), src: res.data ,status: false});
			}
		},
    //图片展示
    setUrl(url) {
      if(url.indexOf("http")==0){
        return url;
      }else{
        return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
      }
      // return url
		},
    //打开弹窗
    openCoverDialog() {
      this.coverchoice = {};
      this.getimgList(this.editor.txt.html())
			this.dialogCover = true;
    },
    //选择封面
    changeCover(tar){
      if(!this.coverchoice.index){
        this.coverchoice = tar;
        this.coverchoice.status = true;
      }else{
        if(this.coverchoice.index == tar.index){
          this.coverchoice.status = !this.coverchoice.status;
        }else{
          this.coverchoice = tar;
          this.coverchoice.status = true;
        }
      }
    },
    //确定封面
    subCover(){
      if(_.isEmpty(this.coverchoice)){
        this.$message.error("无封面图片！");
      }
      else{
        // console.log(this.coverchoice)
        if(this.coverchoice.status){
          this.cover = _.cloneDeep(this.coverchoice.src);

          this.dialogCover = false;
        }else{
          this.$message.error("无选中封面图片！");
        }
      }
    },
    //保存
    saveArt(){
      let sdata = {
        title: this.artform.title,
        content: this.editor.txt.html(),
        contentTxt: this.editor.txt.text().replace(/&nbsp;/g,'').replace(/\s/g,""),
        showImg: this.cover,
        classId: this.artType
      }
      if(sdata.title==""){
        this.$message.error("标题未填写或为空！请确认");
        return false;
      }
      if(sdata.contentTxt == ""){
        this.$message.error("内容未填写或为空！请确认");
        return false;
      }
      if(sdata.showImg==""){
        this.$message.error("封面未选择或上传！请确认");
        return false;
      }
      if(sdata.classId==""){
        this.$message.error("文章未选分类！请确认");
        return false;
      }
      
      let url = '/officialWebsiteMgr/resource/article/add';
      if(this.curStatus=="edit"){
        url = "/officialWebsiteMgr/resource/article/edit";
        sdata.id = this.$route.params.id;
      }
      instance.post(url, sdata).then((res) => {
        if (res.code == 0) {
          this.$router.push({
            name: 'MyArticleList',
          })
        }
      })
    },
    //编辑初始化
		initFrom() {
			instance
				.post('/officialWebsiteMgr/resource/article/desc', {
					id: this.$route.params.id,
				})
				.then((res) => {
					if (res.code == 0) {
            this.artform.title = res.data.title;
            this.cover = res.data.showImg;
						this.fileList = [
							{
								name: res.data.showImg,
								url:
									'/officialWebsiteMgr/resource/file/getImage?fileName=' +
									res.data.showImg,
							},
						]
            this.editor.txt.html(res.data.content);
            this.artType = res.data.classId;
						// this.getEditHtml(res.data.content)
					}
				})
    },
    //清空form
    clearFrom() {
			this.editor.txt.clear();
      this.editor.change && this.editor.change();
      this.artform.title= "";
      this.cover= "";
    },
    scrollFn(){
      if(document.documentElement.scrollTop>150){
        this.editorBarFlool = true;
      }else{
        this.editorBarFlool = false;
      }
    },
    //文章分类
    getArtClassify(){
      instance
				.post('/officialWebsiteMgr/resource/article/classList',{})
				.then((res) => {
					if (res.code == 0) {
            this.artTypeList = res.data;
					}
				})
    },
    
    goBackList(){
      this.$router.push({
        name: 'MyArticleList',
      })
    }
	},
}
</script>
