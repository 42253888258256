import Vue from 'vue'
import ElementUI from 'element-ui';
import  'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'

import "@/assets/font/iconfont.css";

import App from '@/App.vue';
import router from "@/router";
import store from "@/store/index"

import axios from "axios"
Vue.config.productionTip = false

Vue.use(ElementUI);

axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.use(BaiduMap, {
  ak: "fVHmAyaTdIfZQVfiqxRx1akd"
})
Vue.prototype.$addStorageEvent = function (key, data) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function (k, val) {
            localStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        }
    }
    return storage.setItem(key, data);
}
Vue.prototype.$removeStorageEvent = function (key) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        removeItem: function (k) {
            localStorage.removeItem(k);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('removeItem', false, false, k, null, null, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        }
    }
    return storage.removeItem(key);
}
new Vue({
  router,
  store,
  watch: {
    // 监测路径改变相应的 title
    $route: {
      handler: function() {
        document.title = "娱有趣";
      },
      immediate: true
    }
  },
  render: h => h(App),
}).$mount('#app')
