<template>
	<div class="art_bg">
		<el-container class="content_main">
			<el-main class="content_main_context">
				<div class="art_detail">
					<h3>{{ articleDetail.title }}</h3>
					<p class="art_detail_time">
						<span class="el-icon-time">{{
							momentFormat(articleDetail.publishTime)
						}}</span>
					</p>
					<div
						class="art_detail_con"
						v-html="articleDetail.content"
					></div>
					<div class="art_detail_good">
						<span class="like" @click="like">点赞：{{articleDetail.likeCount}}</span>
						<!-- <span class="dis">{{articleDetail.commentCount}}</span> -->
					</div>
				</div>
				<reply-comment :rtype="sendType" :rid="sendId"></reply-comment>
			</el-main>
			<el-aside class="content_main_aside">
        <el-button class="artback_btn" type="primary" @click="goList">返回</el-button>
				<div class="art_detail_user">
					<img
						:src="showImg(articleDetail.userPhoto)"
						@click="toDetail(item.id)"
					/>
					<p>{{ articleDetail.userName }}</p>
				</div>
				<div class="art_hotList art_detail_hot">
					<h3>24小时热帖榜</h3>
					<ul>
						<li v-for="(item, index) in artNewList" :key="item.id">
							<div>{{ index + 1 }}</div>
							<div>
								<p @click="toDetail(item.id)">
									{{ item.title }}
								</p>
								<p>
									<span class="look">{{
										item.pageviews
									}}</span>
									<span class="dis">{{
										item.commentCount
									}}</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</el-aside>
		</el-container>
	</div>
</template>
<script>
import fetch from '@/services/service'
import instance from '@/services/instance'
import moment from 'moment'
import _ from 'lodash'
import ReplyComment from '@/components/replyComment'
export default {
	name: 'articleDetail',
	components: {
		ReplyComment,
	},
	data() {
		return {
			articleDetail: {},
			artNewList: [],
			parmid: '',
			mydis: '',
			disList: [],
			sendType: 1,
			sendId: this.$route.params.id,
		}
	},
	created() {
		this.getCont()
		this.getHotList()
		// this.getDisList();
	},
	watch: {
		$route() {
			this.parmid = this.$route.params.id
			this.sendId = this.$route.params.id
		},
		parmid() {
			this.getCont()
		},
	},
	methods: {
		getCont() {
			fetch
				.post('/officialWebsiteMgr/resource/article/desc', {
					id: this.$route.params.id,
				})
				.then((res) => {
					if (res.code == 0) {
						this.articleDetail = res.data
					}
				})
		},
		momentFormat(time) {
			return moment(time).format('YY-MM-DD HH:mm:ss')
		},
		showImg(url) {
			return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
		},
		getHotList() {
			fetch
				.post('/officialWebsiteMgr/resource/article/hotArticle',{})
				.then((res) => {
					if (res.code == 0) {
						this.artNewList = res.data
					}
				})
		},
		toDetail(id) {
            window.open("/#/article/articleDetail/"+id, '_blank');
			/*this.$router.push({
				name: 'ArticleDetail',
				params: { id: id },
			})*/
		},
		like() {
			let sdata = {
				type: 1,
				pId: this.$route.params.id,
				goodFlag: 1,
			}
			instance
				.post('/officialWebsiteMgr/resource/like/upDown', sdata)
				.then((res) => {
					console.log(res)
					if (res.code == 0) {
						this.articleDetail.likeCount = this.articleDetail.likeCount+1;
					}
				})
    },
		goList(){
		    window.close();
		  /*this.$router.push({
			name: "Article"
		  })*/
		}
	},
}
</script>
