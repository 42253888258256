<template>
    <div>
        <div class="jumpCont">
            <span class="el-icon-loading"></span>正在登录......
        </div>
    </div>
</template>
<script>
import instance from '@/services/instance'

export default {
    name: "jumpLogin",
    data() {
        return {

        }
    },
    created(){
        let code = this.$route.params.code;
        let msg = this.$route.params.msg;
        this.jump(code,msg)
    },
    methods:{
        jump(code,msg){
            let that = this;
            setTimeout(function(){
                if(code==0){//  0 登录成功 1未有微信关联账号，请先注册 2请先绑定电话 3您的账号已被暂停使用
                    that.getUserMessage();
                }else if(code==1){
                    that.$message.error(msg);
                    //that.$message.error("未有微信关联账号，请先注册!");
                    that.$router.push({path: '/register'});
                }else if(code==2){
                    that.$message.error(msg);
                    //that.$message.error("请先绑定手机号!");
                    that.$router.push({path: '/phone'});
                }else{
                    that.$message.error(msg);
                    //that.$message.error("您的账号已被暂停使用!");
                    that.$router.push({path: '/'});
                }
            },2000 )
        },
        getUserMessage() {
            instance
                .post('/officialWebsiteMgr/resource/user/userDesc')
                .then(res => {
                    if (res.code == 0) {
                        this.$addStorageEvent('userInfo', JSON.stringify(res.data));//存储数据,addStorageEvent自定义方法
                        this.$router.push({path: '/mySpace'});
                    }
                })
        },
    }
}
</script>

<style scoped>
.jumpCont{
    width:1120px;
    margin:10px auto;
    border:solid #d7d7d7 1px;
    background:#fff;
    padding:100px 0;
    text-align: center;
}
</style>