
const state = {
    token: 0,
    user_name: "",
    user_photo: "",
};
const getters = {
    isLogin: state=>{
        return state.token == "1";
    },
};
const mutations = {
    UPDATE_USER_INFO(state,obj){
        state.token = obj.token;
        state.user_name = obj.user_name;
        state.user_photo = obj.user_photo;
    },
    REMOVE_USER_INFO(state){
        state.token = 0;
        state.user_name = "";
        state.user_photo = "";
    }
};
const actions = {
    update_user_info({commit},data){
        commit("UPDATE_USER_INFO",data);
        let info = {
            token: 1,
            user_name: data.user_name,
            user_photo: data.user_photo
        };
        sessionStorage.setItem("login-info", JSON.stringify(info));
    },
    remove_user_info({commit}){
        commit("REMOVE_USER_INFO");
        sessionStorage.removeItem("login-info");
    },
    get_user_info({commit},data){
        let info = JSON.parse(sessionStorage.getItem("login-info"));
        if(!info){
            return ""
        }else{
            commit("UPDATE_USER_INFO",info)
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};