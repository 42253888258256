<template>
	<div>
		<el-carousel :interval="5000" arrow="always" height="300">
			<el-carousel-item>
				<div class="banner">
					<img src="~@/assets/img/banner1.jpg" alt="">
				</div>
			</el-carousel-item>
		</el-carousel>
		<div class="hc_menu_block">
			<div class="hc_menu">
				<el-menu 
					mode="horizontal" 
					:default-active="type" 
					@select="menuSelect" 
					active-text-color="#409EFF">
					<el-menu-item v-for="item of typeList" :key="item.id" :index="item.id">{{item.className}}</el-menu-item>
				</el-menu>
				<div class="hc_search">
					<el-input placeholder="请输入查询内容" v-model="searchTxt" size="mini" clearable @clear="getCoolList">
						<el-button slot="append" icon="el-icon-search" @click="getCoolList"></el-button>
					</el-input>
				</div>
			</div>
			<div class="hc_menu_nav">
				<el-button size="mini" round :type="type1==type?'primary':''" @click="changeNav(type)">全部</el-button>
				<el-button 
					v-for="item of typeList1" 
					:key="item.id" w
					size="mini" 
					round
					:type="type1==item.id?'primary':''"
					@click="changeNav(item.id)"
					>{{item.className}}</el-button>
			</div>
		</div>
		<el-container class="content_main">
			<el-main class="content_main_context" v-if="coolList.length!=0" v-loading="loading">
				<div class="artlist">
					<ul>
						<li v-for="item in coolList" :key="item.id">
							<div class="artlist_point">
								<p
									class="artlist_title"
									@click="toDetail(item.id)"
								>
									{{ item.name }}
								</p>
								<p class="artlist_content" @click="toDetail(item.id)">
									{{ content(item.content) }}
								</p>
								<p class="artlist_looked">
									{{ setMoment(item.publishTime) }}
									<span class="look">{{
										item.pageviews
									}}</span>
									<span class="like">{{
										item.likeCount
									}}</span>
									<span class="discount">{{
										item.commentCount
									}}</span>
								</p>
							</div>
							<div class="artlist_img" @click="toDetail(item.id)">
								<img :src="setUrl(item.showImg)" />
							</div>
						</li>
					</ul>
				</div>
				<el-pagination
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-size="10"
					layout="total,  prev, pager, next, jumper"
					:total="totalCool"
				>
				</el-pagination>
			</el-main>
      <el-main v-else>
        数据空
      </el-main>
			<el-aside class="content_main_aside">
				<div class="art_hotList">
					<h3>24小时热帖榜</h3>
					<ul>
						<li v-for="(item,index) in coolHotList" :key="item.id">
							<div>{{index+1}}</div>
							<div>
								<p @click="toDetail(item.id)">{{item.name}}</p>
								<p>
									<span class="look">{{item.pageviews}}</span>
									<span class="dis">{{item.commentCount}}</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
        <div class="adlist">
          <el-carousel height="200px" indicator-position="outside">
            <el-carousel-item v-for="item in adlist" :key="item.fdCode">
              <div class="adimg">
                <a :href="item.fdClickUrl" target="_blank">
                  <img :src="item.fdPicurl">
                </a>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
			</el-aside>
		</el-container>
	</div>
</template>
<script>
import '@/assets/css/art.scss'
import fetch from '@/services/service'
import moment from 'moment'
export default {
	name: 'atricle',
	data() {
		return {
			coolList: [],
			currentPage: 1,
			totalCool: 0,

      coolHotList: [],
      adlist: [],

			type: "",
			type1: "",
			typeList: [],
			typeList1: [],
			searchTxt:"",

			loading: false,
		}
	},
	created() {
		this.initType();
    this.getHotList();
		this.getAd();
	},
	computed:{
		content(){
			return cont=>{
				let word = JSON.parse(cont).filter(item=>{
					return item.type=="words"
				})
				let _cont = word.map(item=>{
					return item.cont
				})
				return _cont.join(",")
			}
		},
		setMoment() {
			return time=>{
				return moment(time).format('YY-MM-DD HH:mm:ss')
			}
		},
		setUrl() {
			return url=>{
				if(url.indexOf("http")==0){
					return url;
				}else{
					return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
				}
			}
		},
	},
	methods: {
		initType(){
			this.gerCoolClassify(this.type).then(res=>{
				this.typeList = res;
				this.type = this.$route.params.type;
				this.gerCoolClassify(this.type).then(res=>{
					this.typeList1 = res;
					this.type1 = this.$route.params.id;
					this.currentPage = 1;
					this.getCoolList();
				})
			});
		},
		gerCoolClassify(id) {
			return new Promise((reslove, reject) => {
				fetch.post('/officialWebsiteMgr/resource/show_favor/classList', {pid:id}).then((res) => {
					if (res.code == 0) {
						reslove(res.data)
					}
				})
			})
		},
		menuSelect(key,keyPath){
			this.type=key;
			this.gerCoolClassify(key).then(res=>{
				this.typeList1 = res;
				this.type1 = key;
				this.currentPage = 1;
				this.getCoolList();
			})
		},
		changeNav(key){
			this.type1 = key;
			this.currentPage = 1;
			this.getCoolList();
		},
		getCoolList() {
			this.loading = true;
			let sdata = {
        classId:  this.type1,
        name: this.searchTxt,
				startPage: this.currentPage,
				pageSize: 10,
			}
			fetch
				.post(
					'/officialWebsiteMgr/resource/show_favor/indexList',
					sdata
				)
				.then((res) => {
					this.loading = false;
					if (res.code == 0) {
						this.coolList = res.data.list
						this.totalCool = res.data.totalCount
					}
				})
		},

		handleSizeChange() {},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getCoolList()
		},

		toDetail(id) {
			/*this.$router.push({
				name: 'CoolDetail',
				params: { id: id },
			})*/
            window.open("/#/CoolDetail/"+id, '_blank');
		},
		getHotList() {
			fetch
				.post('/officialWebsiteMgr/resource/show_favor/hotShowFavor')
				.then((res) => {
					if (res.code == 0) {
						this.coolHotList = res.data
					}
				})
    },
    getAd(){
      fetch.get("https://www.fishsea.cn/fdAdmin/api/getFd?fd_place_code=11&&fd_plat_code=8").then(res=>{
        if(res.code==0){
          this.adlist = res.fds;
        }
      })
    },
	},
}
</script>
