<template>
    <div class="reg">
        <div class="regCont">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="注册" name="reg">
                    <div>
                        账号:
                        <el-input v-model="loginId" placeholder="请输入账号" style="margin:5px 0 10px;"></el-input>
                    </div>
                    <div>
                        昵称:
                        <el-input v-model="nickname" placeholder="请输入昵称" style="margin:5px 0 10px;"></el-input>
                    </div>
                    <div>
                        密码:
                        <el-input placeholder="请输入密码" v-model="password" show-password style="margin:5px 0 10px;"></el-input>
                    </div>
                    <div>
                        确认密码:
                        <el-input placeholder="请输入确认密码" v-model="samepassword" show-password style="margin:5px 0 10px;"></el-input>
                    </div>
                    <div>
                        协议:
                        <el-checkbox v-model="agreement"><router-link to="/agreement">用户协议</router-link></el-checkbox>
                    </div>
                    <div>
                        <el-button type="primary" style="width:100%;margin-top:10px;" @click="reg">注册</el-button>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import instance from '@/services/instance'

    export default {
        name: "register",
        data() {
            return {
                activeName:"reg",
                loginId:"",
                nickname:"",
                password:"",
                samepassword:"",
                agreement:""
            }
        },
        methods:{
            handleClick(tab, event) {
                console.log(tab, event);
            },
            reg(){
                if(this.loginId==""){
                    this.$message.error("请输入账号");
                    return false;
                }
                if(this.nickname==""){
                    this.$message.error("请输入昵称");
                    return false;
                }
                if(this.password==""){
                    this.$message.error("请输入6~12位英文与数字组合");
                    return false;
                }else{
                    if(this.password!=this.samepassword){
                        this.$message.error("两次密码不一致");
                        return false;
                    }
                }
                if(this.securityCode==""){
                    this.$message.error("请输入验证码");
                    return false;
                }
                if(!this.agreement){
                    this.$message.error("未勾选用户协议");
                    return false;
                }
                instance
                    .post('/officialWebsiteMgr/resource/register',{loginId: this.loginId,password:this.password,userName:this.nickname})
                    .then((res) => {
                        if (res.code == 0) {
                            this.$confirm('注册成功，去登录?', '提示', {
                                confirmButtonText: '去登录',
                                showCancelButton:false,
                            }).then(() => {
                                this.$router.push({path: '/login'})
                            })
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
            }
        }
    }
</script>

<style scoped>
.regCont{
    width:500px;
    margin:20px auto;
    background:#fff;
    padding:20px;
}
</style>