// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/login_bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".loginBg[data-v-6f4caf4f]{background:#497fe3 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")no-repeat top;width:100%;min-height:1080px}.loginCont[data-v-6f4caf4f]{width:1000px;margin:0 auto}.login[data-v-6f4caf4f]{width:360px;height:380px;padding:20px;float:right;border-shadow:-2px 0 5px #1a67b1;margin-top:20px;background:#fff}.wxLogin[data-v-6f4caf4f]{font-size:14px;color:#999;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:20px}.wxLogin img[data-v-6f4caf4f]{margin-left:10px}", ""]);
// Exports
module.exports = exports;
