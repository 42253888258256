<template>
    <div>
        <div class="pet-menu">
            <div class="pet-menu-logo">
                <img src="@/assets/img/wang.png" alt="">
                <b>汪星人</b>
            </div>
            <div class="pet-menu-kinds">
                <span v-for="item in petNameList" :key="item.id">{{item.name}}</span>
            </div>
        </div>
        <el-container>
            <el-main class="ss">
                <div class="pet-detail-box">
                    <div class="pet-detail-img-box">
                        <el-image
                            :src="imgurl"
                            fit="contain"></el-image>
                    </div>
                    <div class="pet-detail-txt-box">
                        <h3>苏格兰牧羊犬</h3>
                        <h4>详细特征</h4>
                        <div class="pet-detail-feature">
                            <div v-for="(item,index) in featureList" :key="index">
                                <span class="pet-detail-feature-tag-name">{{item.tag}}：</span>
                                <p class="pet-detail-feature-tag-num">
                                    <span v-for="ite in item.num" :key="ite"></span>
                                </p>
                            </div>
                        </div>
                        <div class="pet-detail-txt">
                            {{detailTxt}}
                        </div>
                    </div>
                </div>
            </el-main>
            <el-aside width="220px">
                <div class="pet-detail-oth">
                    <h3>店铺萌宠</h3>
                    <ul class="pet-detail-bao">
                        <li v-for="item in baoList" :key="item.id">
                            <img :src="item.img" alt="">
                            <p>{{item.txt}}</p>
                        </li>
                    </ul>
                </div>
            </el-aside>
        </el-container>
    </div>
</template>
<script>
export default {
    name: "wangDetail",
    data(){
        return{
            petNameList: [
                {id: 1,name: "金毛"},
                {id: 2,name: "哈士奇"},
                {id: 3,name: "泰迪犬"},
                {id: 4,name: "阿拉斯加雪橇"},
                {id: 5,name: "贵宾犬"},
                {id: 6,name: "德牧"},
            ],
            imgurl: require("@/assets/img/6.jpg"),
            featureList: [
                {tag: "粘人程度", num: 2},
                {tag: "喜叫程度", num: 2},
                {tag: "掉毛程度", num: 3},
                {tag: "对小孩友善", num: 2},
                {tag: "体味程度", num: 2},
                {tag: "动物友善", num: 2},
                {tag: "美容频度", num: 4},
                {tag: "对生人友善", num: 2},
                {tag: "运动量", num: 2},
                {tag: "耐寒程度", num: 5},
                {tag: "城市适应度", num: 2},
                {tag: "口水程度", num: 2},
            ],
            detailTxt: "来自当地叫可利的黑羊。和许多其他犬种一样，深得维多利亚女王的恩宠。1860年，当女王亲临苏格兰访问时，携带数来自当地叫可利的黑羊。和许多其他犬种一样，深得维多利亚女王的恩宠。1860年，当女王亲临苏格兰访问时，携带数来自当地叫可利的黑羊。和许多其他犬种一样，深得维多利亚女王的恩宠。1860年，当女王亲临苏格兰访问时，携带数 只返回温沙堡饲养。于是，在英国逐渐成为广受好评的牧羊犬",
            baoList:[
                {id: 1, img: require("@/assets/img/3.jpg"), txt: "小苍店金牌苏格兰牧羊犬"},
                {id: 2, img: require("@/assets/img/3.jpg"), txt: "小苍店金牌苏格兰牧羊犬"},
            ],
        }
    }
}
</script>
<style scoped>
    .ss{
        min-height: auto;
    }
    .pet-detail-box{
        background: url("~@/assets/img/bookBg.png") repeat-y left top;
        padding: 20px;
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        display: flex;
        border-radius: 3px;
    }
    .pet-detail-img-box{
        float: left;
        width: 240px;
        height: 300px;
    }
    .pet-detail-img-box .el-image{
        width: 240px;
        height: 300px;
    }
    .pet-detail-txt-box{
        flex: 1;
        margin-left: 20px;
    }
    .pet-detail-txt-box> h3{
        font-size: 20px;
        padding: 0;
        margin: 0;
        height: 25px;
        line-height: 25px;
    }
    .pet-detail-txt-box> h4{
        font-size: 14px;
        padding: 0;
        margin: 0;
        padding-left: 20px;
        background: url("~@/assets/img/detail.png") no-repeat left center;
        margin-top: 10px;
        font-weight: normal;
        height: 20px;
        line-height: 20px;
    }
    .pet-detail-feature{
        width: 500px;
        margin-left: 10px;
        padding: 10px 0;
        display: inline-block;
    }
    .pet-detail-feature>div{
        height: 30px;
        width: 250px;
        float: left;
    }
    .pet-detail-feature-tag-name{
        float: left;
        width: 80px;
        height: 30px;
        line-height: 30px;
    }
    .pet-detail-feature-tag-num{
        display: block;
        width: 100px;
        height: 30px;
        background: url("~@/assets/img/start_off.png") repeat-x left center;
        float: left;
        margin: 0;
        padding: 0;
    }
    .pet-detail-feature-tag-num span{
        display: inline-block;
        height: 30px;
        width: 20px;
        background: url("~@/assets/img/start_on.png") no-repeat center;
    }
    .pet-detail-bao{
        margin: 0;
        padding: 0;
        margin-left: 20px;
    }
    .pet-detail-bao li{
        margin-bottom: 20px;
        width: 200px;
        height: 150px;
        position: relative;
        border-radius: 3px;
    }
    .pet-detail-bao li > img{
        height: 150px;
        width: 200px;
    }
    .pet-detail-bao li > p{
        position: absolute;
        bottom: 10px;
        font-size: 12px;
        width: 160px;
        left: 20px;
        background: rgba(255,255,255,0.7);
        height: 25px;
        line-height: 25px;
        text-align: center;
        margin: 0;
    }
    .pet-detail-oth{
        margin: 0;
    }
    .pet-detail-oth h3{
        font-size: 14px;
        font-weight: normal;
        padding-left: 40px;
        height: 30px;
        line-height: 30px;
        background: url("~@/assets/img/oth.png") no-repeat left center;
        margin: 0;
    }
</style>
	