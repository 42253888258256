<template>
	<div class="pet_area">
		<div class="pet-detail-box">
      <div class="pet_type_box">
        <p>
          炫物分类：
          <el-select v-model="type0" size="mini" @change="changeType0" placeholder="一级炫物类型" clearable>
            <el-option v-for="item of typeList0" :key="item.id" :label="item.className" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="type1" size="mini" @change="changeType1" placeholder="二级炫物类型" clearable>
            <el-option v-for="item of typeList1" :key="item.id" :label="item.className" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="type2" size="mini" placeholder="三级炫物类型" clearable>
            <el-option v-for="item of typeList2" :key="item.id" :label="item.className" :value="item.id"></el-option>
          </el-select>
        </p>
      </div>
      <div class="pet_box">
        <h2 class="pet_name">
          <el-input v-model.trim="coolName" placeholder="炫物名称" maxlength="30" show-word-limit></el-input>
        </h2>
        <div class="cool_content">
          <div v-for="(item,index) of coolContent" :key="index" class="cool_ct_block">
            <div :class="'cool_ct'+item.type">
              	<p v-if="item.type=='words'" v-show="index!=editIndex">{{item.cont}}</p>
              	<img :src="setUrl(item.cont)" v-if="item.type=='img'">
				<div class="editTxt" v-show="editIndex==index">
					<el-input
						type="textarea"
						resize="none"
						autosize
						placeholder="请输入内容"
						v-model="editTxt">
					</el-input>
					<p style="text-align: right;margin-top:5px;">
						<el-button size="mini" @click="closeEdit(index)">取消</el-button>
						<el-button size="mini" type="primary" @click="saveEdit(index)">保存</el-button>
					</p>
				</div>
            </div>
            <div class="cont_btn">
              	<el-button size="mini" round :disabled="index==0" @click="indexUp(index)">上移</el-button>
              	<el-button size="mini" round :disabled="index==coolContent.length-1" @click="indexDown(index)">下移</el-button>
				<el-button size="mini" round @click="indexTop(index)">置顶</el-button>
				<el-button size="mini" round @click="indexBtm(index)">置底</el-button>
				<el-button size="mini" round @click="showEdit(index)" v-if="item.type=='words'">编辑</el-button>
				<el-button size="mini" round @click="indexDel(index)">删除</el-button>
            </div>
          </div>
        </div>
        <div class="cool_content_btn">
			<div v-show="allTxt==true" class="allTxtCont">
				<el-input v-model="txt" type="textarea" placeholder="文字输入区域" resize="none" autosize></el-input>
				<p>
					<el-button round size="mini" @click="allTxt=false" class="cont_btn">取消</el-button>
					<el-button type="primary" round size="mini" @click="addText" class="cont_btn">确定</el-button>
				</p>
			</div>
          <div class="cont_btn_box">
            <el-upload
              action="/officialWebsiteMgr/resource/file/addImage"
              :on-success="addImg"
              :show-file-list=false
              class="cont_btn"
            >
              <el-button type="primary" round size="mini">添加图片</el-button>
            </el-upload>
            <el-button type="primary" round size="mini" @click="allTxt=true" class="cont_btn">添加文字</el-button>
          </div>
        </div>
				<div class="pet_photos">
					<el-carousel :interval="4000" type="card" height="300px" v-if="coolPhotos.length!=0">
						<el-carousel-item v-for="item of coolPhotos" :key="item.key">
							<div class="pet_photos_item">
								<img :src="item.src">
							</div>
						</el-carousel-item>
					</el-carousel>
					<p class="pet_photos_btn">
						炫物图集:图片数量上限5,展示比例4:3
						<el-upload
							action="/officialWebsiteMgr/resource/file/addImage"
							:on-success="handleSuccess"
							:on-exceed="handleExceed"
							:show-file-list=false
							multiple
							limit="5"
							v-if="coolPhotos.length!=5"
						>
							<el-button type="primary" round size="mini">上传图集</el-button>
						</el-upload>
					</p>
					<div class="pet_photos_list">
						<div v-for="item of coolPhotos" :key="item.key">
							<img :src="item.src">
							<div>
								<el-button type="danger" icon="el-icon-delete" circle @click="photosDel(item.key)"></el-button>
							</div>
						</div>
					</div>
				</div>
        <div class="pet_cover_cre">
					<div class="pet_cover_box" v-if="coolCover" >
						<img :src="setUrl(coolCover)" >
						<div>
							<span
								@click="coverRemove"
								class="el-icon-delete"
							>
							</span>
						</div>
					</div>
          <el-upload
            v-else
            action="/officialWebsiteMgr/resource/file/addImage"
            :show-file-list="false"
            :on-success="handleSuccesscoolCover"
          >
            <div class="pet_cover_note" > 
              <b>+</b>
              <p>炫物封面</p>
              <p>最优尺寸高宽比5:4</p>
            </div>
          </el-upload>
        </div>
        <div class="pet_des_box">
					<h4>详细特征：标签上限12（添加特征的炫物与非添加的布局不同）</h4>
					<div class="pet_rate_box">
						<div class="pet_rate_item" v-for="(item,index) of tagList" :key="index">
							<!-- <label>{{item.tag}}：</label> -->
							<el-input v-model="item.tag" size="mini" maxlength="6"></el-input>
							<el-rate 
								v-model="item.num"
								void-icon-class="iconfont icon-pethome-xingxing"
								:icon-classes="['iconfont icon-pethome-xingxing','iconfont icon-pethome-xingxing','iconfont icon-pethome-xingxing']"
								void-color="#ccc"
								:colors="['#049aee','#049aee','#049aee']"
								class="pet_rate"
							></el-rate>
							<el-button class="pet_item_del" icon="el-icon-delete" type="info" circle size="mini" @click="tagDel(index)"></el-button>
						</div>
						<el-button class="pet_item_add" size="mini" type="primary" round v-if="tagList.length!=12" @click="tagAdd">添加新标签</el-button>
					</div>
				</div>
      </div>
		</div>
		<div class="pet_fun">
      <div>
        <el-button @click="importTemp">导入模板</el-button>
        <el-button type="warning" @click="clear">清空</el-button>
        <el-button type="success" @click="refresh" v-if="status=='edit'">还原</el-button>
        <el-button type="primary" @click="save(0)">保存</el-button>
		  <el-button type="primary" @click="save(3)">发布</el-button>
        <el-button type="info" @click="goback">返回</el-button>
      </div>
		</div>
	</div>
</template>
<script>
import instance from '@/services/instance';
import E from 'wangeditor';

export default {
	name: 'PetCreate',
	data() {
		return {
			coolCover: "",
			tagList: [],
			templateCool:[
				{ tag: '粘人程度', num: 2 },
				{ tag: '喜叫程度', num: 2 },
				{ tag: '掉毛程度', num: 3 },
				{ tag: '对小孩友善', num: 2 },
				{ tag: '体味程度', num: 2 },
				{ tag: '动物友善', num: 2 },
				{ tag: '美容频度', num: 4 },
				{ tag: '对生人友善', num: 2 },
				{ tag: '运动量', num: 2 },
				{ tag: '耐寒程度', num: 5 },
				{ tag: '城市适应度', num: 2 },
			],
      	coolName: "",
      	coolContent: [],
			coolPhotos: [],
			type0: "",
			type1: "",
			type2: "",
			typeList0: [],
			typeList1: [],
			typeList2: [],
			status: "",
			editId: "",
			editorBarFlool: false,  //悬浮
			txt: "",
            editIndex:-1,
            editTxt:"",
            allTxt:false,
		}
  },
  computed:{
    curStatus(){
      return this.status;
    },
  },
  watch:{
    $route(){
      this.status = this.$route.params.type;
    },
    curStatus(){
      if(this.curStatus=="edit"){
        this.fillFrom()
      }else{
        this.clear();
      }
    }
  },
  mounted() {
    this.status = this.$route.params.type;
  },
  created(){
    this.getArtClassify(this.type0).then(res=>{
      this.typeList0 = res;
    })
  },
  methods:{
    //得到分类
    getArtClassify(id){
      return new Promise((reslove,reject)=>{
        instance.post('/officialWebsiteMgr/resource/show_favor/classList',{pId:id}).then((res) => {
          if (res.code == 0) {
            reslove(res.data)
          }
          else{
            reject(res.msg)
          }
        })
      })
    },
    changeType0(){
      this.type1 = "";
      this.type2 = "";
      this.typeList1 = [];
      this.typeList2 = [];
      this.getArtClassify(this.type0).then(res=>{
        this.typeList1 = res;
      })
    },
    changeType1(){
      this.type2 = "";
      this.typeList2 = [];
      this.getArtClassify(this.type1).then(res=>{
        this.typeList2 = res;
      })
    },
    //封面上传
		handleSuccesscoolCover(res){
			if (res.code == 0) {
				this.coolCover = res.data;
			}else{
				this.$message.error("上传失败!")
			}
    },
    coverRemove(){
      this.coolCover = "";
    },
	//导入模板
	importTemp(){
		if(this.tagList.length!=0){
			this.$confirm('此操作将覆盖原有标签, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.tagList = _.cloneDeep(this.templateCool);
			}).catch(() => {});
		}else{
			this.tagList = _.cloneDeep(this.templateCool)
		}
	},
	//删除标签
	tagDel(ind){
		this.tagList.splice(ind,1)
	},
	//添加标签
	tagAdd(){
		let tag = { tag: '新标签', num: 2 };
		this.tagList.push(tag);
	},
	//图集上传成功
	handleSuccess(res) {
		if (res.code == 0) {
			if(this.coolPhotos.length<5){
				this.coolPhotos.push({
					key: _.uniqueId("photo_"),
					src: this.setUrl(res.data),
					url:res.data
				});
			}else{
				this.$message.error("超出展示上限!")
			}
		}
	},
      handleExceed(files, fileList) {
          this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
	//图片URL
	setUrl(url){
		return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
	},
	photosDel(key){
		let photos = _.cloneDeep(this.coolPhotos);
		let rephoto = _.remove(photos,function(item){
			return item.key==key
		})
		this.$nextTick(() => {
			this.coolPhotos = photos;
  		});
	},

    //清空
    clear(){
      this.coolCover = "";
      this.tagList = [];
      this.coolName = "";
      this.coolPhotos = [];
      this.type0 = "";
      this.type1 = "";
      this.type2 = "";
      this.typeList1 = [];
      this.typeList2 = [];
      this.coolContent = [];
    },
    //保存
    save(status){
      console.log(this.coolContent)
      console.log(JSON.stringify(this.coolContent))
      let sdata = {
        classId: this.type2==""?(this.type1==""?this.type0:this.type1):this.type2,
        name: this.coolName,
        content: JSON.stringify(this.coolContent),
        bannerImg: this.coolPhotos.map(item=>{
          return item.url;
        }).join(","),
        showImg: this.coolCover,
        showTag: JSON.stringify(this.tagList),
		status:status,//0保存 3发布
      }
      console.log(sdata.bannerImg)
      if(this.type0==""){
        return this.errorAlert("请确认选择炫物分类！")
      }
      
      if(sdata.name==""){
        return this.errorAlert("请填写炫物名称！")
      }
      if(sdata.content=="[]"){
        return this.errorAlert("请填写描述！")
      }
      if(sdata.showImg==""){
        return this.errorAlert("请上传炫物封面图！")
      }
      // if(sdata.bannerImg==""){
      //   return this.errorAlert("请上传至少一张炫物图集！")
      // }
      if(this.tagList.length!=0){
        let status = true;
        for(let item of this.tagList){
          console.log(item)
          if(_.trim(item.tag)==""){
            status = false;
            break;
          }
        }
        if(!status){
          return this.errorAlert("特征标签描述不能为空！");
        }
      }
      // return false;
      if(this.status=="edit"){
        sdata.id = this.editId;
      }
      let url = this.status=="create"?"/officialWebsiteMgr/resource/show_favor/add":"/officialWebsiteMgr/resource/show_favor/edit";
      instance.post(url,sdata).then(res=>{
        if(res.code==0){
          this.$message.success("保存成功！");
          this.$router.push({
            name: 'MyPetList',
          })
        }
      })
    },
    errorAlert(mes){
      this.$message.error(mes);
      return false;
    },
    //还原
    refresh(){
      this.fillFrom()
    },

    //编辑回填
    fillFrom(){
      instance.post("/officialWebsiteMgr/resource/show_favor/desc",{id:this.$route.params.id}).then(res=>{
        if (res.code == 0) {
          let _res = res.data;
          this.coolName = _res.name;
          this.coolCover = _res.showImg;
          this.tagList = JSON.parse(_res.showTag);
          this.coolContent = JSON.parse(_res.content);
          console.log(_res)
          if(_res.bannerImg!=""){
            let banner = _res.bannerImg.split(",");
            this.coolPhotos = banner.map(item=>{
              return {
                key: _.uniqueId("photo_"),
                src: this.setUrl(item),
                url: item,
              }
            });
          }
          this.type0 = _res.p1ClassId;
          this.getArtClassify(this.type0).then(data=>{
            this.typeList1 = data;
            if(_res.pClassId!=null){
              this.type1 = _res.pClassId;
              this.getArtClassify(this.type1).then(data2=>{
                this.typeList2 = data2;
                if(_res.p3ClassId!=null){
                  this.type2 = _res.p3ClassId;
                }
              });  
            }
            
          });
          this.editId = _res.id;
        }
      })
    },

    goback(){
        this.$router.push({
            name: 'MyPetList',
        })
    },
    addText(){
      if(this.txt==""){
        this.$message.error("添加内容为空");
        return false;
      }else{
        let ary = {type:"words",cont:this.txt};
        this.coolContent.push(ary);
        this.txt = "";
        this.allTxt=false;
      }
    },
    addImg(res){
      if (res.code == 0) {
        let ary = {type:"img",cont: res.data};
        this.coolContent.push(ary);
	  }else{
		this.$message.error("上传失败!")
	  }
    },
	showEdit(index){
	  this.editIndex=index;
	  this.editTxt=this.coolContent[index].cont;
	},
	closeEdit(){
	  this.editIndex=-1;
	},
	saveEdit(index){
	  this.coolContent[index].cont = this.editTxt;
	  this.editIndex=-1;
	  this.editTxt="";
	},
	indexUp(index){
	  this.coolContent[index] = this.coolContent.splice(index-1, 1, this.coolContent[index])[0];
	},
	indexDown(index){
	  this.coolContent[index] = this.coolContent.splice(index+1, 1, this.coolContent[index])[0];
	},
	indexTop(index){
	  this.coolContent.unshift(this.coolContent.splice(index,1)[0]);
	},
	indexBtm(index){
      this.coolContent.push(this.coolContent.splice(index, 1)[0]);
	},
	indexDel(index){
	  this.coolContent.splice(index,1);
	}
  }
}
</script>
