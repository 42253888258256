<template>
  <div>
    <el-carousel :interval="5000" arrow="always" height="300">
			<el-carousel-item>
				<div class="banner">
					<img src="~@/assets/img/banner1.jpg" alt="">
				</div>
			</el-carousel-item>
		</el-carousel>
    <div class="cool">
      <div class="cool_block">
        <h2></h2>
        <div class="cool_area">
          <div class="cool_banner" @click="toList('00002','00002')">
            <img src="~@/assets/demo/cool_banner_00.png">
          </div>
          <div class="cool_type">
            <div>
              <div class="type_left havrgba" @click="toList('00002','00014')">
                <img src="~@/assets/demo/type_0_0.png">
                <div class="type_title"> 
                  <h2>猫</h2>
                  <h4>爱我宠我亲亲我</h4>
                  <h4>Love me and kiss me</h4>
                  <p>more <i class="iconfont icon-pethome-more"></i></p>
                </div>
              </div>
              <div class="type_right havrgba" @click="toList('00002','00015')">
                <img src="~@/assets/demo/type_0_1.png">
                <div class="type_title"> 
                  <h2>狗</h2>
                  <h4>忠诚可爱的伙伴</h4>
                  <h4>Loyal and lovely partner</h4>
                  <p>more <i class="iconfont icon-pethome-more"></i></p>
                </div>
              </div>
            </div>
            <div>
              <div class="type_right havrgba" @click="toList('00002','00017')">
                <img src="~@/assets/demo/type_0_2.png">
                <div class="type_title"> 
                  <h2>鱼</h2>
                  <h4>水中的小精灵</h4>
                  <h4>Water elves</h4>
                  <p>more <i class="iconfont icon-pethome-more"></i></p>
                </div>
              </div>
              <div class="type_left havrgba" @click="toList('00002','00016')">
                <img src="~@/assets/demo/type_0_3.png">
                <div class="type_title"> 
                  <h2>小宠</h2>
                  <h4>手掌里的宠物</h4>
                  <h4>Pet in the palm</h4>
                  <p>more <i class="iconfont icon-pethome-more"></i></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cool_block">
        <h2></h2>
        <div class="cool_area">
          <div class="cool_banner" @click="toList('00005','00005')">
            <img src="~@/assets/demo/cool_banner_10.png">
          </div>
          <div class="cool_type">
            <div>
              <div class="type_right" @click="toList('00005','00031')">
                <img src="~@/assets/demo/type_1_0.png">
              </div>
              <div class="type_left" @click="toList('00005','00032')">
                <img src="~@/assets/demo/type_1_1.png">
              </div>
            </div>
            <div>
              <div class="type_left" @click="toList('00005','00031')">
                <img src="~@/assets/demo/type_1_2.png">
              </div>
              <div class="type_right" @click="toList('00005','00032')">
                <img src="~@/assets/demo/type_1_3.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cool_block">
        <h2></h2>
        <div class="cool_area">
          <div class="cool_banner" @click="toList('00003','00003')">
            <img src="~@/assets/demo/cool_banner_20.png">
          </div>
          <div class="cool_type">
            <div>
              <div class="type_left" @click="toList('00003','00029')">
                <img src="~@/assets/demo/type_2_0.png">
              </div>
              <div class="type_right" @click="toList('00003','00030')">
                <img src="~@/assets/demo/type_2_1.png">
              </div>
            </div>
            <div>
              <div class="type_right" @click="toList('00003','00030')">
                <img src="~@/assets/demo/type_2_2.png">
              </div>
              <div class="type_left" @click="toList('00003','00029')">
                <img src="~@/assets/demo/type_2_3.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cool_block">
        <h2></h2>
        <div class="cool_area">
           <div class="cool_banner" @click="toList('00004','00004')">
            <img src="~@/assets/demo/cool_banner_30.png">
          </div>
          <div class="cool_type">
            <div>
              <div class="type_right" @click="toList('00004','00027')">
                <img src="~@/assets/demo/type_3_0.png">
              </div>
              <div class="type_left" @click="toList('00004','00028')">
                <img src="~@/assets/demo/type_3_1.png">
              </div>
            </div>
            <div>
              <div class="type_left" @click="toList('00004','00027')">
                <img src="~@/assets/demo/type_3_2.png">
              </div>
              <div class="type_right" @click="toList('00004','00028')">
                <img src="~@/assets/demo/type_3_3.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CoolThings",
  methods:{
    toList(type,id){
      this.$router.push({
				name: 'CoolList',
				params: {type: type, id: id},
			})
    },
  }
}
</script>